import React from "react";

import SvgIcon from "../SvgIcon/SvgIcon";
import GitHub from "../../assets/icons/github.svg";
import LinkedIn from "../../assets/icons/linkedin.svg";
import Instagram from "../../assets/icons/instagram.svg";

import "./Contact.scss";

export default class Contact extends React.Component {
  componentDidMount() {
    const contact = document.getElementsByClassName("contact")[0];
    const contactHeading = document.getElementsByClassName(
      "contact__heading"
    )[0];
    const contactHeadingUnderline = document.getElementsByClassName(
      "contact__heading-underline"
    )[0];
    const contactFirst = document.getElementsByClassName("contact__first")[0];
    const contactSelf = document.getElementsByClassName(
      "contact__self-icon"
    )[0];
    const contactSecond = document.getElementsByClassName("contact__second")[0];
    const contactEmail = document.getElementsByClassName("contact__email")[0];
    const contactSocials = document.getElementsByClassName(
      "contact__social-container"
    )[0];

    this.contactSectionObserver.observe(contact);
    this.contactHeadingObserver.observe(contactHeading);
    this.contactHeadingUnderlineObserver.observe(contactHeadingUnderline);
    this.contactIntroObserver.observe(contactFirst);
    this.contactSelfObserver.observe(contactSelf);
    this.contactSecondObserver.observe(contactSecond);
    this.contactEmailObserver.observe(contactEmail);
    this.contactSocialsObserver.observe(contactSocials);
  }

  contactSectionOptions = {
    root: null,
    rootMargin: "0px",
    threshold: [0, 0.4, 0.6, 1],
  };

  contactSectionCallback = (entries, options) => {
    const home = document.getElementsByClassName("home")[0];
    const observedEl = entries[0];
    const heading = document.getElementsByClassName("contact__heading")[0];
    const headingUnderline = document.getElementsByClassName(
      "contact__heading-underline"
    )[0];

    if (this.props.viewWidth >= 1024 && this.props.viewHeight > 1000) {
      if (
        observedEl.isIntersecting &&
        observedEl.intersectionRatio > 0.6 &&
        observedEl.intersectionRatio < 0.7
      ) {
        home.classList.remove("home--background-skills");
        home.classList.add("home--background-white");
        heading.classList.add("contact__heading--black");
        headingUnderline.classList.add("contact__heading-underline--black");
      }
    } else if (
      observedEl.isIntersecting &&
      observedEl.intersectionRatio > 0.4 &&
      observedEl.intersectionRatio < 0.6
    ) {
      if (
        this.props.viewWidth >= 375 &&
        this.props.viewWidth <= 768 &&
        this.props.viewHeight > 800
      ) {
      } else if (this.props.viewWidth >= 1024 && this.props.viewHeight > 1000) {
      } else {
        home.classList.remove("home--background-skills");
        home.classList.add("home--background-white");
        heading.classList.add("contact__heading--black");
        headingUnderline.classList.add("contact__heading-underline--black");
      }
    } else if (
      this.props.viewWidth >= 375 &&
      this.props.viewWidth <= 768 &&
      this.props.viewHeight > 800
    ) {
      if (
        observedEl.isIntersecting &&
        observedEl.intersectionRatio > 0.6 &&
        observedEl.intersectionRatio < 0.7
      ) {
        home.classList.remove("home--background-skills");
        home.classList.add("home--background-white");
        heading.classList.add("contact__heading--black");
        headingUnderline.classList.add("contact__heading-underline--black");
      }
    }
    if (!observedEl.isIntersecting) {
      home.classList.remove("home--background-white");
      heading.classList.remove("contact__heading--black");
      headingUnderline.classList.remove("contact__heading-underline--black");
    }
  };

  contactHeadingOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  contactHeadingCallback = (entries, options) => {
    const observedEl = entries[0];
    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("contact__heading--animate");
    } else {
      observedEl.target.classList.remove("contact__heading--animate");
    }
  };

  contactHeadingUnderlineCallback = (entries, options) => {
    const observedEl = entries[0];
    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("contact__heading-underline--animate");
    } else {
      observedEl.target.classList.remove("contact__heading-underline--animate");
    }
  };

  contactIntroCallback = (entries, options) => {
    const observedEl = entries[0];
    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("contact__first--animate");
    } else {
      observedEl.target.classList.remove("contact__first--animate");
    }
  };

  contactSelfCallback = (entries, options) => {
    const observedEl = entries[0];
    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("contact__self-icon--animate");
    } else {
      observedEl.target.classList.remove("contact__self-icon--animate");
    }
  };

  contactSecondCallback = (entries, options) => {
    const observedEl = entries[0];
    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("contact__second--animate");
    } else {
      observedEl.target.classList.remove("contact__second--animate");
    }
  };

  contactEmailCallback = (entries, options) => {
    const observedEl = entries[0];
    const third = document.getElementsByClassName("contact__third")[0];
    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("contact__email--animate");
      third.classList.add("contact__email--animate");
    } else {
      observedEl.target.classList.remove("contact__email--animate");
      third.classList.remove("contact__email--animate");
    }
  };

  contactSocialsCallback = (entries, options) => {
    const observedEl = entries[0];
    const anchors = document.getElementsByClassName("contact__anchor");
    if (observedEl.isIntersecting) {
      for (let i = 0; i < anchors.length; i++) {
        anchors[i].classList.add(`contact__anchor--animate${i}`);
      }
    } else {
      for (let i = 0; i < anchors.length; i++) {
        anchors[i].classList.remove(`contact__anchor--animate${i}`);
      }
    }
  };

  contactSectionObserver = new IntersectionObserver(
    this.contactSectionCallback,
    this.contactSectionOptions
  );

  contactHeadingObserver = new IntersectionObserver(
    this.contactHeadingCallback,
    this.contactHeadingOptions
  );

  contactHeadingObserver = new IntersectionObserver(
    this.contactHeadingCallback,
    this.contactHeadingOptions
  );

  contactHeadingUnderlineObserver = new IntersectionObserver(
    this.contactHeadingUnderlineCallback,
    this.contactHeadingOptions
  );

  contactIntroObserver = new IntersectionObserver(
    this.contactIntroCallback,
    this.contactHeadingOptions
  );

  contactSelfObserver = new IntersectionObserver(
    this.contactSelfCallback,
    this.contactHeadingOptions
  );

  contactSecondObserver = new IntersectionObserver(
    this.contactSecondCallback,
    this.contactHeadingOptions
  );

  contactEmailObserver = new IntersectionObserver(
    this.contactEmailCallback,
    this.contactHeadingOptions
  );

  contactSocialsObserver = new IntersectionObserver(
    this.contactSocialsCallback,
    this.contactHeadingOptions
  );

  render() {
    return (
      <section className="contact">
        <h2 className="contact__heading">Contact</h2>
        <div className="contact__heading-underline"></div>
        <div className="contact__message">
          <p className="contact__first">
            Lets make <br />
            something great!{" "}
          </p>
          <SvgIcon icon="self" class="contact__self-icon" />
          <p className="contact__second">
            I am currently looking for a full-time software development role.
          </p>
          <a
            className="contact__email"
            href="mailto:royk.leejr@gmail.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            royk.leejr@gmail.com
          </a>
          <div className="contact__third">for opportunities</div>
        </div>
        <div className="contact__social-container">
          <a
            className="contact__anchor"
            href="https://github.com/royleejr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="contact__icons" src={GitHub} alt="Github logo" />
          </a>
          <a
            className="contact__anchor"
            href="https://www.linkedin.com/in/roy-lee-jr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="contact__icons"
              src={LinkedIn}
              alt="LinkedIn Logo"
            />
          </a>
          <a
            className="contact__anchor"
            href="https://www.instagram.com/roykleejr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="contact__icons"
              src={Instagram}
              alt="Instagram Logo"
            />
          </a>
        </div>
      </section>
    );
  }
}
