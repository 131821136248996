import React from "react";

import throttle from "../../utility/throttle";
import SvgIcon from "../SvgIcon/SvgIcon";

import "./Project.scss";

export default class Project extends React.Component {
  componentDidMount() {
    const id = this.props.data.id;
    const projectSection = document.getElementsByClassName("project");

    this.projectSectionObserver.observe(projectSection[id]);

    const projectNumber = document.getElementsByClassName(
      "project__project-number"
    );
    this.projectNumberObserver.observe(projectNumber[id]);

    const projectHero = document.getElementsByClassName(
      "project__image-container"
    );
    this.projectHeroObserver.observe(projectHero[id]);

    const projectLink = document.getElementsByClassName(
      "project__link-container"
    );
    const projectDescription = document.getElementsByClassName(
      "project__description"
    );
    const tech = document.getElementsByClassName("project__container-margin");
    const techIcon = document.getElementsByClassName("project__tech-icon");
    const galleryTitle = document.getElementsByClassName(
      "project__gallery-title"
    );
    const galleryFigure = document.getElementsByClassName("project__figure");

    const galleryImage = document.getElementsByClassName(
      "project__gallery-image"
    );
    const galleryContainer = document.getElementsByClassName(
      "project__gallery-image-container"
    );
    const figure = document.getElementsByClassName("project__figure");
    const galleryFigureLeft = document.getElementsByClassName(
      "project__figures-left"
    );
    const galleryFigureRight = document.getElementsByClassName(
      "project__figures-right"
    );

    const singleLink = document.getElementsByClassName(
      "project__single-link-container"
    );
    const projectLinkContainer = document.getElementsByClassName(
      "project__link-container"
    );
    if (id === 0) {
      this.projectLinkObserver.observe(projectLink[0]);
      this.projectDescriptionObserver.observe(projectDescription[0]);
      this.projectDescriptionObserver.observe(projectDescription[1]);
      this.projectTechObserver.observe(tech[0]);
      this.projectGalleryTitleObserver.observe(galleryTitle[0]);
      this.projectGalleryImageOneObserver.observe(galleryFigure[0]);
      this.projectGalleryImageTwoObserver.observe(galleryFigure[1]);
      this.projectGalleryImageThreeObserver.observe(galleryFigure[2]);
      this.projectGalleryImageFourObserver.observe(galleryFigure[3]);
      techIcon[0].classList.add("project__tech-hover--omdb");
      techIcon[1].classList.add("project__tech-hover--omdb");
      techIcon[2].classList.add("project__tech-hover--omdb");
      techIcon[3].classList.add("project__tech-hover--omdb");
    } else if (id === 1) {
      this.projectLinkObserver.observe(projectLink[1]);
      this.projectDescriptionObserver.observe(projectDescription[2]);
      this.projectDescriptionObserver.observe(projectDescription[3]);
      this.projectTechObserver.observe(tech[1]);
      this.projectGalleryTitleObserver.observe(galleryTitle[1]);
      this.projectGalleryImageOneObserver.observe(galleryFigure[4]);
      this.projectGalleryImageTwoObserver.observe(galleryFigure[5]);
      this.projectGalleryImageThreeObserver.observe(galleryFigure[6]);
      this.projectGalleryImageFourObserver.observe(galleryFigure[7]);
      techIcon[4].classList.add("project__tech-hover--mountadex");
      techIcon[5].classList.add("project__tech-hover--mountadex");
    } else if (id === 2) {
      this.projectLinkObserver.observe(projectLink[2]);
      this.projectDescriptionObserver.observe(projectDescription[4]);
      this.projectDescriptionObserver.observe(projectDescription[5]);
      this.projectTechObserver.observe(tech[2]);
      this.projectGalleryTitleObserver.observe(galleryTitle[2]);
      this.projectGalleryImageOneObserver.observe(galleryFigure[8]);
      this.projectGalleryImageTwoObserver.observe(galleryFigure[9]);
      this.projectGalleryImageThreeObserver.observe(galleryFigure[10]);
      this.projectGalleryImageFourObserver.observe(galleryFigure[11]);
      for (let i = 8; i < 12; i++) {
        galleryImage[i].classList.add("project__gallery-image--event-friends");
        galleryContainer[i].classList.add(
          "project__gallery-image--event-friends"
        );
      }
      singleLink[4].classList.add("project--hide");
      projectLinkContainer[2].classList.add("project__link-container--single");
      galleryFigureLeft[2].classList.add("project__figures-left--event");
      galleryFigureRight[2].classList.add("project__figures-right--event");
      techIcon[6].classList.add("project__tech-hover--event");
      techIcon[7].classList.add("project__tech-hover--event");
      techIcon[8].classList.add("project__tech-hover--event");
      figure[8].classList.add("project__figure--event-one");
      figure[9].classList.add("project__figure--event-two");
      figure[10].classList.add("project__figure--event-three");
      figure[11].classList.add("project__figure--event-four");
    } else if (id === 3) {
      this.projectLinkObserver.observe(projectLink[3]);
      this.projectDescriptionObserver.observe(projectDescription[6]);
      this.projectDescriptionObserver.observe(projectDescription[7]);
      this.projectTechObserver.observe(tech[3]);
      this.projectGalleryTitleObserver.observe(galleryTitle[3]);
      this.projectGalleryImageOneObserver.observe(galleryFigure[12]);
      this.projectGalleryImageTwoObserver.observe(galleryFigure[13]);
      this.projectGalleryImageThreeObserver.observe(galleryFigure[14]);
      this.projectGalleryImageFourObserver.observe(galleryFigure[15]);
      for (let i = 12; i < 16; i++) {
        galleryImage[i].classList.add("project__gallery-image--check");
        galleryContainer[i].classList.add("project__gallery-image--check");
      }
      figure[12].classList.add("project__figure--check-one");
      figure[13].classList.add("project__figure--check-two");
      figure[14].classList.add("project__figure--check-three");
      figure[15].classList.add("project__figure--check-four");
      techIcon[9].classList.add("project__tech-hover--check");
      techIcon[10].classList.add("project__tech-hover--check");
    } else if (id === 4) {
      this.projectLinkObserver.observe(projectLink[4]);
      this.projectDescriptionObserver.observe(projectDescription[8]);
      this.projectDescriptionObserver.observe(projectDescription[9]);
      this.projectTechObserver.observe(tech[4]);
      this.projectGalleryTitleObserver.observe(galleryTitle[4]);
      this.projectGalleryImageOneObserver.observe(galleryFigure[16]);
      this.projectGalleryImageTwoObserver.observe(galleryFigure[17]);
      this.projectGalleryImageThreeObserver.observe(galleryFigure[18]);
      this.projectGalleryImageFourObserver.observe(galleryFigure[19]);
      techIcon[11].classList.add("project__tech-hover--dog");
      techIcon[12].classList.add("project__tech-hover--dog");
      techIcon[13].classList.add("project__tech-hover--dog");
    } else if (id === 5) {
      this.projectLinkObserver.observe(projectLink[5]);
      this.projectDescriptionObserver.observe(projectDescription[10]);
      this.projectDescriptionObserver.observe(projectDescription[11]);
      this.projectTechObserver.observe(tech[5]);
      this.projectGalleryTitleObserver.observe(galleryTitle[5]);
      this.projectGalleryImageOneObserver.observe(galleryFigure[20]);
      this.projectGalleryImageTwoObserver.observe(galleryFigure[21]);
      this.projectGalleryImageThreeObserver.observe(galleryFigure[22]);
      this.projectGalleryImageFourObserver.observe(galleryFigure[23]);
      techIcon[14].classList.add("project__tech-hover--brainflix");
      techIcon[15].classList.add("project__tech-hover--brainflix");
      techIcon[16].classList.add("project__tech-hover--brainflix");
      techIcon[17].classList.add("project__tech-hover--brainflix");
    } else if (id === 6) {
      this.projectLinkObserver.observe(projectLink[6]);
      this.projectDescriptionObserver.observe(projectDescription[12]);
      this.projectDescriptionObserver.observe(projectDescription[13]);
      this.projectTechObserver.observe(tech[6]);
      this.projectGalleryTitleObserver.observe(galleryTitle[6]);
      this.projectGalleryImageOneObserver.observe(galleryFigure[24]);
      this.projectGalleryImageTwoObserver.observe(galleryFigure[25]);
      this.projectGalleryImageThreeObserver.observe(galleryFigure[26]);
      this.projectGalleryImageFourObserver.observe(galleryFigure[27]);
      for (let i = 24; i < 28; i++) {
        galleryImage[i].classList.add("project__gallery-image--event-friends");
        galleryContainer[i].classList.add(
          "project__gallery-image--event-friends"
        );
      }
      galleryFigureLeft[6].classList.add("project__figures-left--event");
      galleryFigureRight[6].classList.add("project__figures-right--event");
      figure[24].classList.add("project__figure--event-one");
      figure[25].classList.add("project__figure--event-two");
      figure[26].classList.add("project__figure--event-three");
      figure[27].classList.add("project__figure--event-four");
      techIcon[18].classList.add("project__tech-hover--dragcity");
      techIcon[19].classList.add("project__tech-hover--dragcity");
    }
  }

  projectSectionOptions = {
    root: null,
    rootMargin: "0px",
    threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  };

  projectSectionCallback = (entries, options) => {
    const observedEl = entries[0];
    const homePage = document.getElementsByClassName("home")[0];
    const skipButton = document.getElementsByClassName("skip-button")[0];
    if (
      observedEl.target.getBoundingClientRect().top > 100 &&
      observedEl.isIntersecting &&
      observedEl.target.getBoundingClientRect().top < 600
    ) {
      if (this.props.data.number === "01") {
        homePage.classList.add("home--background-omdb");
        homePage.classList.remove("home--background-white");
        this.props.projectLocation("omdb");
        skipButton.className = "skip-button skip-button--animate";
      } else if (this.props.data.number === "02") {
        homePage.classList.add("home--background-mountadex");
        this.props.projectLocation("mountadex");
      } else if (this.props.data.number === "03") {
        homePage.classList.add("home--background-event");
        this.props.projectLocation("event");
      } else if (this.props.data.number === "04") {
        homePage.classList.add("home--background-check");
        this.props.projectLocation("check");
      } else if (this.props.data.number === "05") {
        homePage.classList.add("home--background-dog");
        this.props.projectLocation("dog");
      } else if (this.props.data.number === "06") {
        homePage.classList.add("home--background-brainflix");
        this.props.projectLocation("brainflix");
      } else if (this.props.data.number === "07") {
        homePage.classList.add("home--background-dragcity");
        this.props.projectLocation("dragcity");
      }
    } else if (observedEl.isIntersecting && observedEl.intersectionRatio < 1) {
      if (this.props.data.number === "01") {
        homePage.classList.add("home--background-omdb");
        this.props.projectLocation("omdb");
      } else if (this.props.data.number === "02") {
        homePage.classList.add("home--background-mountadex");
        this.props.projectLocation("mountadex");
      } else if (this.props.data.number === "03") {
        homePage.classList.add("home--background-event");
        this.props.projectLocation("event");
      } else if (this.props.data.number === "04") {
        homePage.classList.add("home--background-check");
        this.props.projectLocation("check");
      } else if (this.props.data.number === "05") {
        homePage.classList.add("home--background-dog");
        this.props.projectLocation("dog");
      } else if (this.props.data.number === "06") {
        homePage.classList.add("home--background-brainflix");
        this.props.projectLocation("brainflix");
      } else if (this.props.data.number === "07") {
        homePage.classList.add("home--background-dragcity");
        this.props.projectLocation("dragcity");
        if (skipButton.className.includes("animate")) {
        } else {
          skipButton.className = "skip-button skip-button--animate";
        }
      }
    } else if (!observedEl.isIntersecting) {
      if (this.props.data.number === "01") {
        homePage.classList.remove("home--background-omdb");
      } else if (this.props.data.number === "02") {
        homePage.classList.remove("home--background-mountadex");
      } else if (this.props.data.number === "03") {
        homePage.classList.remove("home--background-event");
      } else if (this.props.data.number === "04") {
        homePage.classList.remove("home--background-check");
      } else if (this.props.data.number === "05") {
        homePage.classList.remove("home--background-dog");
      } else if (this.props.data.number === "06") {
        homePage.classList.remove("home--background-brainflix");
      } else if (this.props.data.number === "07") {
        homePage.classList.remove("home--background-dragcity");
      }
    }
  };

  projectNumberOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  projectTechOptions = {
    root: null,
    rootMargin: "0px",
    threshold: [0, 0.2],
  };

  projectNumberCallback = (entries, options) => {
    const observedEl = entries[0];
    if (this.props.data.number === "01") {
      const projectHeading = document.getElementsByClassName(
        "project__heading"
      )[0];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__project-number--animate");
        projectHeading.classList.add("project__heading--animate");
      } else {
        observedEl.target.classList.remove("project__project-number--animate");
        projectHeading.classList.remove("project__heading--animate");
      }
    } else if (this.props.data.number === "02") {
      const projectHeading = document.getElementsByClassName(
        "project__heading"
      )[1];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__project-number--animate");
        projectHeading.classList.add("project__heading--animate");
      } else {
        observedEl.target.classList.remove("project__project-number--animate");
        projectHeading.classList.remove("project__heading--animate");
      }
    } else if (this.props.data.number === "03") {
      const projectHeading = document.getElementsByClassName(
        "project__heading"
      )[2];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__project-number--animate");
        projectHeading.classList.add("project__heading--animate");
      } else {
        observedEl.target.classList.remove("project__project-number--animate");
        projectHeading.classList.remove("project__heading--animate");
      }
    } else if (this.props.data.number === "04") {
      const projectHeading = document.getElementsByClassName(
        "project__heading"
      )[3];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__project-number--animate");
        projectHeading.classList.add("project__heading--animate");
      } else {
        observedEl.target.classList.remove("project__project-number--animate");
        projectHeading.classList.remove("project__heading--animate");
      }
    } else if (this.props.data.number === "05") {
      const projectHeading = document.getElementsByClassName(
        "project__heading"
      )[4];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__project-number--animate");
        projectHeading.classList.add("project__heading--animate");
      } else {
        observedEl.target.classList.remove("project__project-number--animate");
        projectHeading.classList.remove("project__heading--animate");
      }
    } else if (this.props.data.number === "06") {
      const projectHeading = document.getElementsByClassName(
        "project__heading"
      )[5];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__project-number--animate");
        projectHeading.classList.add("project__heading--animate");
      } else {
        observedEl.target.classList.remove("project__project-number--animate");
        projectHeading.classList.remove("project__heading--animate");
      }
    } else if (this.props.data.number === "07") {
      const projectHeading = document.getElementsByClassName(
        "project__heading"
      )[6];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__project-number--animate");
        projectHeading.classList.add("project__heading--animate");
      } else {
        observedEl.target.classList.remove("project__project-number--animate");
        projectHeading.classList.remove("project__heading--animate");
      }
    }
  };

  projectHeroCallback = (entries, options) => {
    const observedEl = entries[0];
    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("project__image-container--animate");
    } else {
      observedEl.target.classList.remove("project__image-container--animate");
    }
  };

  projectLinkCallback = (entries, options) => {
    const observedEl = entries[0];
    const link = document.getElementsByClassName(
      "project__underline-container"
    );
    if (observedEl.isIntersecting) {
      if (this.props.data.number === "01") {
        link[0].classList.add("project__underline-container--animate-one");
        link[1].classList.add("project__underline-container--animate-two");
      } else if (this.props.data.number === "02") {
        link[2].classList.add("project__underline-container--animate-one");
        link[3].classList.add("project__underline-container--animate-two");
      } else if (this.props.data.number === "03") {
        link[4].classList.add("project__underline-container--hide");
        link[5].classList.add("project__underline-container--animate-one");
      } else if (this.props.data.number === "04") {
        link[6].classList.add("project__underline-container--animate-one");
        link[7].classList.add("project__underline-container--animate-two");
      } else if (this.props.data.number === "05") {
        link[8].classList.add("project__underline-container--animate-one");
        link[9].classList.add("project__underline-container--animate-two");
      } else if (this.props.data.number === "06") {
        link[10].classList.add("project__underline-container--animate-one");
        link[11].classList.add("project__underline-container--animate-two");
      } else if (this.props.data.number === "07") {
        link[12].classList.add("project__underline-container--animate-one");
        link[13].classList.add("project__underline-container--animate-two");
      }
    } else {
      if (this.props.data.number === "01") {
        link[0].classList.remove("project__underline-container--animate-one");
        link[1].classList.remove("project__underline-container--animate-two");
      } else if (this.props.data.number === "02") {
        link[2].classList.remove("project__underline-container--animate-one");
        link[3].classList.remove("project__underline-container--animate-two");
      } else if (this.props.data.number === "03") {
        link[4].classList.remove("project__underline-container--animate-one");
        link[5].classList.remove("project__underline-container--animate-two");
      } else if (this.props.data.number === "04") {
        link[6].classList.remove("project__underline-container--animate-one");
        link[7].classList.remove("project__underline-container--animate-two");
      } else if (this.props.data.number === "05") {
        link[8].classList.remove("project__underline-container--animate-one");
        link[9].classList.remove("project__underline-container--animate-two");
      } else if (this.props.data.number === "06") {
        link[10].classList.remove("project__underline-container--animate-one");
        link[11].classList.remove("project__underline-container--animate-two");
      } else if (this.props.data.number === "07") {
        link[12].classList.remove("project__underline-container--animate-one");
        link[13].classList.remove("project__underline-container--animate-two");
      }
    }
  };

  projectDescriptionCallback = (entries, options) => {
    const observedEl = entries[0];
    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("project__description--animate-one");
    } else {
      observedEl.target.classList.remove("project__description--animate-one");
    }
  };

  giveAnimationOnEnd = () => {
    const techIcon = document.getElementsByClassName("project__tech-icon");
    if (this.props.data.number === "01") {
      techIcon[0].classList.add("project__tech-icon--animate-one");
      techIcon[1].classList.add("project__tech-icon--animate-two");
      techIcon[2].classList.add("project__tech-icon--animate-three");
      techIcon[3].classList.add("project__tech-icon--animate-four");
    } else if (this.props.data.number === "02") {
      techIcon[4].classList.add("project__tech-icon--animate-one");
      techIcon[5].classList.add("project__tech-icon--animate-two");
    } else if (this.props.data.number === "03") {
      techIcon[6].classList.add("project__tech-icon--animate-one");
      techIcon[7].classList.add("project__tech-icon--animate-two");
      techIcon[8].classList.add("project__tech-icon--animate-three");
    } else if (this.props.data.number === "04") {
      techIcon[9].classList.add("project__tech-icon--animate-one");
      techIcon[10].classList.add("project__tech-icon--animate-two");
    } else if (this.props.data.number === "05") {
      techIcon[11].classList.add("project__tech-icon--animate-one");
      techIcon[12].classList.add("project__tech-icon--animate-two");
      techIcon[13].classList.add("project__tech-icon--animate-three");
    } else if (this.props.data.number === "06") {
      techIcon[14].classList.add("project__tech-icon--animate-one");
      techIcon[15].classList.add("project__tech-icon--animate-two");
      techIcon[16].classList.add("project__tech-icon--animate-three");
      techIcon[17].classList.add("project__tech-icon--animate-four");
    } else if (this.props.data.number === "07") {
      techIcon[18].classList.add("project__tech-icon--animate-one");
      techIcon[19].classList.add("project__tech-icon--animate-two");
    }
  };

  projectTechCallback = (entries, options) => {
    const observedEl = entries[0];

    const techIcon = document.getElementsByClassName("project__tech-icon");
    if (observedEl.isIntersecting && observedEl.intersectionRatio > 0.2) {
      if (this.props.data.number === "01") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[0];
        tech.classList.add("project__tech-animate-container--animate");
        tech.addEventListener("animationend", this.giveAnimationOnEnd);
      } else if (this.props.data.number === "02") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[1];
        tech.classList.add("project__tech-animate-container--animate");
        tech.addEventListener("animationend", this.giveAnimationOnEnd);
      } else if (this.props.data.number === "03") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[2];
        tech.classList.add("project__tech-animate-container--animate");
        tech.addEventListener("animationend", this.giveAnimationOnEnd);
      } else if (this.props.data.number === "04") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[3];
        tech.classList.add("project__tech-animate-container--animate");
        tech.addEventListener("animationend", this.giveAnimationOnEnd);
      } else if (this.props.data.number === "05") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[4];
        tech.classList.add("project__tech-animate-container--animate");
        tech.addEventListener("animationend", this.giveAnimationOnEnd);
      } else if (this.props.data.number === "06") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[5];
        tech.classList.add("project__tech-animate-container--animate");
        tech.addEventListener("animationend", this.giveAnimationOnEnd);
      } else if (this.props.data.number === "07") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[6];
        tech.classList.add("project__tech-animate-container--animate");
        tech.addEventListener("animationend", this.giveAnimationOnEnd);
      }
    } else if (
      observedEl.isIntersecting &&
      observedEl.intersectionRatio <= 0.1
    ) {
      if (this.props.data.number === "01") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[0];
        tech.classList.remove("project__tech-animate-container--animate");
        tech.removeEventListener("animationend", this.giveAnimationOnEnd);
        techIcon[0].classList.remove("project__tech-icon--animate-one");
        techIcon[1].classList.remove("project__tech-icon--animate-two");
        techIcon[2].classList.remove("project__tech-icon--animate-three");
        techIcon[3].classList.remove("project__tech-icon--animate-four");
      }
      if (this.props.data.number === "02") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[1];
        tech.classList.remove("project__tech-animate-container--animate");
        tech.removeEventListener("animationend", this.giveAnimationOnEnd);
        techIcon[4].classList.remove("project__tech-icon--animate-one");
        techIcon[5].classList.remove("project__tech-icon--animate-two");
      } else if (this.props.data.number === "03") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[2];
        tech.classList.remove("project__tech-animate-container--animate");
        tech.removeEventListener("animationend", this.giveAnimationOnEnd);
        techIcon[6].classList.remove("project__tech-icon--animate-one");
        techIcon[7].classList.remove("project__tech-icon--animate-two");
        techIcon[8].classList.remove("project__tech-icon--animate-three");
      } else if (this.props.data.number === "04") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[3];
        tech.classList.remove("project__tech-animate-container--animate");
        tech.removeEventListener("animationend", this.giveAnimationOnEnd);
        techIcon[9].classList.remove("project__tech-icon--animate-one");
        techIcon[10].classList.remove("project__tech-icon--animate-two");
      } else if (this.props.data.number === "05") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[4];
        tech.classList.remove("project__tech-animate-container--animate");
        tech.removeEventListener("animationend", this.giveAnimationOnEnd);
        techIcon[11].classList.remove("project__tech-icon--animate-one");
        techIcon[12].classList.remove("project__tech-icon--animate-two");
        techIcon[13].classList.remove("project__tech-icon--animate-three");
      } else if (this.props.data.number === "06") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[5];
        tech.classList.remove("project__tech-animate-container--animate");
        tech.removeEventListener("animationend", this.giveAnimationOnEnd);
        techIcon[14].classList.remove("project__tech-icon--animate-one");
        techIcon[15].classList.remove("project__tech-icon--animate-two");
        techIcon[16].classList.remove("project__tech-icon--animate-three");
        techIcon[17].classList.remove("project__tech-icon--animate-four");
      } else if (this.props.data.number === "07") {
        const tech = document.getElementsByClassName(
          "project__tech-animate-container"
        )[6];
        tech.classList.remove("project__tech-animate-container--animate");
        tech.removeEventListener("animationend", this.giveAnimationOnEnd);
        techIcon[18].classList.remove("project__tech-icon--animate-one");
        techIcon[19].classList.remove("project__tech-icon--animate-two");
      }
    }
  };

  projectGalleryTitleCallback = (entries, options) => {
    const observedEl = entries[0];

    if (this.props.data.number === "01") {
      const underline = document.getElementsByClassName(
        "project__gallery-underline"
      )[0];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__gallery-title--animate");
        underline.classList.add("project__gallery-underline--animate");
      } else {
        observedEl.target.classList.remove("project__gallery-title--animate");
        underline.classList.remove("project__gallery-underline--animate");
      }
    } else if (this.props.data.number === "02") {
      const underline = document.getElementsByClassName(
        "project__gallery-underline"
      )[1];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__gallery-title--animate");
        underline.classList.add("project__gallery-underline--animate");
      } else {
        observedEl.target.classList.remove("project__gallery-title--animate");
        underline.classList.remove("project__gallery-underline--animate");
      }
    } else if (this.props.data.number === "03") {
      const underline = document.getElementsByClassName(
        "project__gallery-underline"
      )[2];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__gallery-title--animate");
        underline.classList.add("project__gallery-underline--animate");
      } else {
        observedEl.target.classList.remove("project__gallery-title--animate");
        underline.classList.remove("project__gallery-underline--animate");
      }
    } else if (this.props.data.number === "04") {
      const underline = document.getElementsByClassName(
        "project__gallery-underline"
      )[3];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__gallery-title--animate");
        underline.classList.add("project__gallery-underline--animate");
      } else {
        observedEl.target.classList.remove("project__gallery-title--animate");
        underline.classList.remove("project__gallery-underline--animate");
      }
    } else if (this.props.data.number === "05") {
      const underline = document.getElementsByClassName(
        "project__gallery-underline"
      )[4];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__gallery-title--animate");
        underline.classList.add("project__gallery-underline--animate");
      } else {
        observedEl.target.classList.remove("project__gallery-title--animate");
        underline.classList.remove("project__gallery-underline--animate");
      }
    } else if (this.props.data.number === "06") {
      const underline = document.getElementsByClassName(
        "project__gallery-underline"
      )[5];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__gallery-title--animate");
        underline.classList.add("project__gallery-underline--animate");
      } else {
        observedEl.target.classList.remove("project__gallery-title--animate");
        underline.classList.remove("project__gallery-underline--animate");
      }
    } else if (this.props.data.number === "07") {
      const underline = document.getElementsByClassName(
        "project__gallery-underline"
      )[6];
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("project__gallery-title--animate");
        underline.classList.add("project__gallery-underline--animate");
      } else {
        observedEl.target.classList.remove("project__gallery-title--animate");
        underline.classList.remove("project__gallery-underline--animate");
      }
    }
  };

  projectAnimate = (number) => {
    if (number === 1) {
      if (this.props.data.number === "01") {
        const projectOne = document.getElementsByClassName(
          "project__gallery-animate--one"
        )[0];
        return projectOne;
      }
      if (this.props.data.number === "02") {
        const projectOne = document.getElementsByClassName(
          "project__gallery-animate--one"
        )[1];
        return projectOne;
      } else if (this.props.data.number === "03") {
        const projectOne = document.getElementsByClassName(
          "project__gallery-animate--one"
        )[2];
        return projectOne;
      } else if (this.props.data.number === "04") {
        const projectOne = document.getElementsByClassName(
          "project__gallery-animate--one"
        )[3];
        return projectOne;
      } else if (this.props.data.number === "05") {
        const projectOne = document.getElementsByClassName(
          "project__gallery-animate--one"
        )[4];
        return projectOne;
      } else if (this.props.data.number === "06") {
        const projectOne = document.getElementsByClassName(
          "project__gallery-animate--one"
        )[5];
        return projectOne;
      } else if (this.props.data.number === "07") {
        const projectOne = document.getElementsByClassName(
          "project__gallery-animate--one"
        )[6];
        return projectOne;
      }
    } else if (number === 2) {
      if (this.props.data.number === "01") {
        const projectTwo = document.getElementsByClassName(
          "project__gallery-animate--two"
        )[0];
        return projectTwo;
      }
      if (this.props.data.number === "02") {
        const projectTwo = document.getElementsByClassName(
          "project__gallery-animate--two"
        )[1];
        return projectTwo;
      } else if (this.props.data.number === "03") {
        const projectTwo = document.getElementsByClassName(
          "project__gallery-animate--two"
        )[2];
        return projectTwo;
      } else if (this.props.data.number === "04") {
        const projectTwo = document.getElementsByClassName(
          "project__gallery-animate--two"
        )[3];
        return projectTwo;
      } else if (this.props.data.number === "05") {
        const projectTwo = document.getElementsByClassName(
          "project__gallery-animate--two"
        )[4];
        return projectTwo;
      } else if (this.props.data.number === "06") {
        const projectTwo = document.getElementsByClassName(
          "project__gallery-animate--two"
        )[5];
        return projectTwo;
      } else if (this.props.data.number === "07") {
        const projectTwo = document.getElementsByClassName(
          "project__gallery-animate--two"
        )[6];
        return projectTwo;
      }
    } else if (number === 3) {
      if (this.props.data.number === "01") {
        const projectThree = document.getElementsByClassName(
          "project__gallery-animate--three"
        )[0];
        return projectThree;
      } else if (this.props.data.number === "02") {
        const projectThree = document.getElementsByClassName(
          "project__gallery-animate--three"
        )[1];
        return projectThree;
      } else if (this.props.data.number === "03") {
        const projectThree = document.getElementsByClassName(
          "project__gallery-animate--three"
        )[2];
        return projectThree;
      } else if (this.props.data.number === "04") {
        const projectThree = document.getElementsByClassName(
          "project__gallery-animate--three"
        )[3];
        return projectThree;
      } else if (this.props.data.number === "05") {
        const projectThree = document.getElementsByClassName(
          "project__gallery-animate--three"
        )[4];
        return projectThree;
      } else if (this.props.data.number === "06") {
        const projectThree = document.getElementsByClassName(
          "project__gallery-animate--three"
        )[5];
        return projectThree;
      } else if (this.props.data.number === "07") {
        const projectThree = document.getElementsByClassName(
          "project__gallery-animate--three"
        )[6];
        return projectThree;
      }
    } else if (number === 4) {
      if (this.props.data.number === "01") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[0];
        return projectFour;
      }
      if (this.props.data.number === "02") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[1];
        return projectFour;
      } else if (this.props.data.number === "03") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[2];
        return projectFour;
      } else if (this.props.data.number === "04") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[3];
        return projectFour;
      } else if (this.props.data.number === "05") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[4];
        return projectFour;
      } else if (this.props.data.number === "06") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[5];
        return projectFour;
      } else if (this.props.data.number === "07") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[6];
        return projectFour;
      }
    } else if (number === 5) {
      if (this.props.data.number === "01") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[0];
        return projectFour;
      } else if (this.props.data.number === "02") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[1];
        return projectFour;
      } else if (this.props.data.number === "03") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[2];
        return projectFour;
      } else if (this.props.data.number === "04") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[3];
        return projectFour;
      } else if (this.props.data.number === "05") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[4];
        return projectFour;
      } else if (this.props.data.number === "06") {
        const projectFour = document.getElementsByClassName(
          "project__gallery-animate--four"
        )[5];
        return projectFour;
      }
    }
  };

  containerAnimate = (number) => {
    if (number === 1) {
      if (this.props.data.number === "01") {
        const containerOne = document.getElementsByClassName(
          "project__gallery-image-container--one"
        )[0];
        return containerOne;
      }
      if (this.props.data.number === "02") {
        const containerOne = document.getElementsByClassName(
          "project__gallery-image-container--one"
        )[1];
        return containerOne;
      } else if (this.props.data.number === "03") {
        const containerOne = document.getElementsByClassName(
          "project__gallery-image-container--one"
        )[2];
        return containerOne;
      } else if (this.props.data.number === "04") {
        const containerOne = document.getElementsByClassName(
          "project__gallery-image-container--one"
        )[3];
        return containerOne;
      } else if (this.props.data.number === "05") {
        const containerOne = document.getElementsByClassName(
          "project__gallery-image-container--one"
        )[4];
        return containerOne;
      } else if (this.props.data.number === "06") {
        const containerOne = document.getElementsByClassName(
          "project__gallery-image-container--one"
        )[5];
        return containerOne;
      } else if (this.props.data.number === "07") {
        const containerOne = document.getElementsByClassName(
          "project__gallery-image-container--one"
        )[6];
        return containerOne;
      }
    } else if (number === 2) {
      if (this.props.data.number === "01") {
        const containerTwo = document.getElementsByClassName(
          "project__gallery-image-container--two"
        )[0];
        return containerTwo;
      } else if (this.props.data.number === "02") {
        const containerTwo = document.getElementsByClassName(
          "project__gallery-image-container--two"
        )[1];
        return containerTwo;
      } else if (this.props.data.number === "03") {
        const containerTwo = document.getElementsByClassName(
          "project__gallery-image-container--two"
        )[2];
        return containerTwo;
      } else if (this.props.data.number === "04") {
        const containerTwo = document.getElementsByClassName(
          "project__gallery-image-container--two"
        )[3];
        return containerTwo;
      } else if (this.props.data.number === "05") {
        const containerTwo = document.getElementsByClassName(
          "project__gallery-image-container--two"
        )[4];
        return containerTwo;
      } else if (this.props.data.number === "06") {
        const containerTwo = document.getElementsByClassName(
          "project__gallery-image-container--two"
        )[5];
        return containerTwo;
      } else if (this.props.data.number === "07") {
        const containerTwo = document.getElementsByClassName(
          "project__gallery-image-container--two"
        )[6];
        return containerTwo;
      }
    } else if (number === 3) {
      if (this.props.data.number === "01") {
        const containerThree = document.getElementsByClassName(
          "project__gallery-image-container--three"
        )[0];
        return containerThree;
      } else if (this.props.data.number === "02") {
        const containerThree = document.getElementsByClassName(
          "project__gallery-image-container--three"
        )[1];
        return containerThree;
      } else if (this.props.data.number === "03") {
        const containerThree = document.getElementsByClassName(
          "project__gallery-image-container--three"
        )[2];
        return containerThree;
      } else if (this.props.data.number === "04") {
        const containerThree = document.getElementsByClassName(
          "project__gallery-image-container--three"
        )[3];
        return containerThree;
      } else if (this.props.data.number === "05") {
        const containerThree = document.getElementsByClassName(
          "project__gallery-image-container--three"
        )[4];
        return containerThree;
      } else if (this.props.data.number === "06") {
        const containerThree = document.getElementsByClassName(
          "project__gallery-image-container--three"
        )[5];
        return containerThree;
      } else if (this.props.data.number === "07") {
        const containerThree = document.getElementsByClassName(
          "project__gallery-image-container--three"
        )[6];
        return containerThree;
      }
    } else if (number === 4) {
      if (this.props.data.number === "01") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[0];
        return containerFour;
      }
      if (this.props.data.number === "02") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[1];
        return containerFour;
      } else if (this.props.data.number === "03") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[2];
        return containerFour;
      } else if (this.props.data.number === "04") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[3];
        return containerFour;
      } else if (this.props.data.number === "05") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[4];
        return containerFour;
      } else if (this.props.data.number === "06") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[5];
        return containerFour;
      } else if (this.props.data.number === "07") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[6];
        return containerFour;
      }
    } else if (number === 5) {
      if (this.props.data.number === "01") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[0];
        return containerFour;
      }
      if (this.props.data.number === "02") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[1];
        return containerFour;
      } else if (this.props.data.number === "03") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[2];
        return containerFour;
      } else if (this.props.data.number === "04") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[3];
        return containerFour;
      } else if (this.props.data.number === "05") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[4];
        return containerFour;
      } else if (this.props.data.number === "06") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[5];
        return containerFour;
      } else if (this.props.data.number === "07") {
        const containerFour = document.getElementsByClassName(
          "project__gallery-image-container--four"
        )[6];
        return containerFour;
      }
    }
  };

  projectGalleryImageCallback = (entries, options) => {
    const observedEl = entries[0];
    if (observedEl.isIntersecting) {
      if (this.props.viewWidth < 1280) {
        observedEl.target.classList.add("project__figure--animate");
      } else {
        if (observedEl.target.className.includes("one")) {
          this.projectAnimate(1).classList.add(
            "project__gallery-animate--one--animate"
          );
          this.projectAnimate(1).onanimationend = () => {
            this.containerAnimate(1).classList.add(
              "project__gallery-image-container--shadow"
            );
          };
        } else if (observedEl.target.className.includes("two")) {
          this.projectAnimate(2).classList.add(
            "project__gallery-animate--two--animate"
          );
          this.projectAnimate(2).onanimationend = () => {
            this.containerAnimate(2).classList.add(
              "project__gallery-image-container--shadow"
            );
          };
        } else if (observedEl.target.className.includes("three")) {
          this.projectAnimate(3).classList.add(
            "project__gallery-animate--three--animate"
          );
          this.projectAnimate(3).onanimationend = () => {
            this.containerAnimate(3).classList.add(
              "project__gallery-image-container--shadow"
            );
          };
        } else if (observedEl.target.className.includes("four")) {
          this.projectAnimate(4).classList.add(
            "project__gallery-animate--four--animate"
          );
          this.projectAnimate(4).onanimationend = () => {
            this.containerAnimate(4).classList.add(
              "project__gallery-image-container--shadow"
            );
          };
        }
      }
    } else {
      if (this.props.viewWidth < 1280) {
        observedEl.target.classList.remove("project__figure--animate");
      } else {
        if (observedEl.target.className.includes("one")) {
          this.projectAnimate(1).classList.remove(
            "project__gallery-animate--one--animate"
          );
          this.containerAnimate(1).classList.remove(
            "project__gallery-image-container--shadow"
          );
        } else if (observedEl.target.className.includes("two")) {
          this.projectAnimate(2).classList.remove(
            "project__gallery-animate--two--animate"
          );
          this.containerAnimate(2).classList.remove(
            "project__gallery-image-container--shadow"
          );
        } else if (observedEl.target.className.includes("three")) {
          this.projectAnimate(3).classList.remove(
            "project__gallery-animate--three--animate"
          );
          this.containerAnimate(3).classList.remove(
            "project__gallery-image-container--shadow"
          );
        } else if (observedEl.target.className.includes("four")) {
          this.projectAnimate(4).classList.remove(
            "project__gallery-animate--four--animate"
          );
          this.containerAnimate(4).classList.remove(
            "project__gallery-image-container--shadow"
          );
        }
      }
    }
  };

  projectSectionObserver = new IntersectionObserver(
    this.projectSectionCallback,
    this.projectSectionOptions
  );

  projectNumberObserver = new IntersectionObserver(
    this.projectNumberCallback,
    this.projectNumberOptions
  );

  projectHeroObserver = new IntersectionObserver(
    this.projectHeroCallback,
    this.projectNumberOptions
  );

  projectLinkObserver = new IntersectionObserver(
    this.projectLinkCallback,
    this.projectNumberOptions
  );

  projectDescriptionObserver = new IntersectionObserver(
    this.projectDescriptionCallback,
    this.projectNumberOptions
  );

  projectTechObserver = new IntersectionObserver(
    this.projectTechCallback,
    this.projectTechOptions
  );

  projectGalleryTitleObserver = new IntersectionObserver(
    this.projectGalleryTitleCallback,
    this.projectNumberOptions
  );

  projectGalleryImageOneObserver = new IntersectionObserver(
    throttle(100, this.projectGalleryImageCallback),
    this.projectNumberOptions
  );
  projectGalleryImageTwoObserver = new IntersectionObserver(
    throttle(100, this.projectGalleryImageCallback),
    this.projectNumberOptions
  );
  projectGalleryImageThreeObserver = new IntersectionObserver(
    throttle(100, this.projectGalleryImageCallback),
    this.projectNumberOptions
  );
  projectGalleryImageFourObserver = new IntersectionObserver(
    throttle(100, this.projectGalleryImageCallback),
    this.projectNumberOptions
  );

  renderImage = (image) => {
    if (this.props.viewWidth < 1280) {
      if (image === "landing") {
        return this.props.data.galleryImageOne.mobile;
      } else if (image === "about") {
        return this.props.data.galleryImageFour.mobile;
      }
    } else {
      if (image === "landing") {
        return this.props.data.galleryImageOne.desktop;
      } else if (image === "about") {
        return this.props.data.galleryImageFour.desktop;
      }
    }
  };

  render() {
    const { data } = this.props;

    return (
      <article className="project">
        <div className="project__container">
          <span className="project__project-number">{data.number}</span>
          <h3 className="project__heading">{data.name}</h3>
          <div className="project__image-container">
            <img
              className="project__image"
              src={data.heroImage.image}
              alt={data.heroImage.alt}
            />
          </div>
          <div className="project__link-container">
            <div className="project__single-link-container project__single-link-container--site">
              <div className="project__underline-container">
                <a
                  className="project__link"
                  href={data.siteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live site
                </a>
                <div className="project__link-underline"></div>
              </div>
            </div>
            <div className="project__single-link-container project__single-link-container--git">
              <div className="project__underline-container">
                <a
                  className="project__link"
                  href={data.gitHubLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </a>
                <div className="project__link-underline"></div>
              </div>
            </div>
          </div>
          <div className="project__description-container">
            <p className="project__description">{data.descriptionOne}</p>
            <p className="project__description">{data.descriptionTwo}</p>
          </div>
          <div className="project__container-margin">
            <div className="project__tech-animate-container">
              <div className="project__techstack-container">
                {data.techStack.map((item) => {
                  return (
                    <SvgIcon
                      icon={item}
                      key={item + data.project}
                      class="project__tech-icon"
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="project__gallery-container">
            <h4 className="project__gallery-title">Gallery</h4>
            <div className="project__gallery-underline"></div>
            <div className="project__figures-container">
              <div className="project__figures-left">
                <figure className="project__figure project__figure--one">
                  <div className="project__gallery-image-container project__gallery-image-container--one">
                    <div className="project__gallery-animate project__gallery-animate--one">
                      <img
                        className="project__gallery-image project__gallery-image--one"
                        src={this.renderImage("landing")}
                        alt={data.galleryImageOne.alt}
                      />
                    </div>
                  </div>
                  <figcaption className="project__caption">
                    {data.galleryImageOne.caption}
                  </figcaption>
                </figure>
                <figure className="project__figure project__figure--two">
                  <div className="project__gallery-image-container  project__gallery-image-container--two">
                    <div className="project__gallery-animate project__gallery-animate--two">
                      <img
                        className="project__gallery-image project__gallery-image--two"
                        src={data.galleryImageTwo.image}
                        alt={data.galleryImageTwo.alt}
                      />
                    </div>
                  </div>
                  <figcaption className="project__caption">
                    {data.galleryImageTwo.caption}
                  </figcaption>
                </figure>
              </div>
              <div className="project__figures-right">
                <figure className="project__figure project__figure--three">
                  <div className="project__gallery-image-container project__gallery-image-container--three">
                    <div className="project__gallery-animate project__gallery-animate--three">
                      <img
                        className="project__gallery-image project__gallery-image--three"
                        src={data.galleryImageThree.image}
                        alt={data.galleryImageThree.alt}
                      />
                    </div>
                  </div>
                  <figcaption className="project__caption">
                    {data.galleryImageThree.caption}
                  </figcaption>
                </figure>
                <figure className="project__figure project__figure--four">
                  <div className="project__gallery-image-container project__gallery-image-container--four">
                    <div className="project__gallery-animate project__gallery-animate--four">
                      <img
                        className="project__gallery-image project__gallery-image--four"
                        src={this.renderImage("about")}
                        alt={data.galleryImageFour.alt}
                      />
                    </div>
                  </div>
                  <figcaption className="project__caption project__caption--four">
                    {data.galleryImageFour.caption}
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}
