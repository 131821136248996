import React from "react";

import HomePage from "./pages/HomePage/HomePage";
import Landing from "./components/Landing/Landing";

import "./styles/App.scss";

let project = null;
let initialSize = null;
export default class App extends React.Component {
  state = {
    loaded: false,
    landingComplete: false,
    viewWidth: window.innerWidth,
    viewHeight: window.innerHeight,
    scrollPosition: 0,
    updateNumber: 0,
    aboutPosition: 0,
    projectsPosition: 0,
    skillsPosition: 0,
    contactPosition: 0,
  };

  componentDidMount() {
    const body = document.querySelector("body");
    if (this.state.viewWidth === 1024 && this.state.viewHeight > 1300) {
      body.classList.remove("body--fixed");
    } else if (this.state.viewWidth < 1024) {
      body.classList.remove("body--fixed");
    } else {
      body.classList.add("body--fixed");
    }

    window.addEventListener("resize", this.updateView);
    window.addEventListener("load", (event) => {
      this.setNavPosition();
      this.setState({ loaded: true });
      document
        .getElementsByClassName("loading-container")[0]
        .setAttribute("style", "display: none;");
    });

    if (window.innerWidth < 1024) {
      initialSize = "mobile";
    } else if (window.innerWidth === 1024 && window.innerHeight > 1300) {
      initialSize = "mobile";
    } else {
      initialSize = "desktop";
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.viewWidth < 1024 &&
      this.state.viewWidth >= 1024 &&
      this.state.viewHeight < 1300
    ) {
      initialSize = "desktop";
    } else if (
      prevState.viewWidth === 1024 &&
      prevState.viewHeight > 1300 &&
      this.state.viewWidth >= 1024 &&
      this.state.viewHeight < 1300
    ) {
      initialSize = "desktop";
    }
  }

  landingToggle = () => {
    this.setState({
      landingComplete: true,
    });
  };

  setNavPosition = () => {
    const headerHeight = document.getElementsByClassName("header")[0]
      .offsetHeight;
    const heroHeight = document.getElementsByClassName("hero-section")[0]
      .offsetHeight;
    const aboutHeight = document.getElementsByClassName("about-section")[0]
      .offsetHeight;
    const projectsHeight = document.getElementsByClassName(
      "projects-section"
    )[0].offsetHeight;
    const skillsHeight = document.getElementsByClassName("skills__container")[0]
      .offsetHeight;

    let aboutPosition = headerHeight + heroHeight;
    let projectsPosition = aboutPosition + aboutHeight;
    let skillsPosition = projectsPosition + projectsHeight;
    let contactPosition = skillsPosition + skillsHeight;

    this.setState({
      aboutPosition: aboutPosition,
      projectsPosition: projectsPosition,
      skillsPosition: skillsPosition,
      contactPosition: contactPosition,
    });
  };

  updateView = () => {
    this.setState({
      viewWidth: window.innerWidth,
      viewHeight: window.innerHeight,
    });
    setTimeout(() => {
      this.setNavPosition();
    }, 1000);
  };

  projectLocation = (proj) => {
    project = proj;
  };

  skipHandler = () => {
    const home = document.getElementsByClassName("home")[0];
    const headerHeight = document.getElementsByClassName("header")[0]
      .offsetHeight;
    const heroHeight = document.getElementsByClassName("hero-section")[0]
      .offsetHeight;
    const aboutHeight = document.getElementsByClassName("about-section")[0]
      .offsetHeight;
    const projectHeadingHeight = document.getElementsByClassName(
      "projects__heading"
    )[0].offsetHeight;
    const projectHeadingUnderline = document.getElementsByClassName(
      "projects__underline"
    )[0].offsetHeight;
    const allProjects = document.getElementsByClassName("project");
    const skillsSection = document.getElementsByClassName("skills-section")[0];
    const button = document.getElementsByClassName("skip-button")[0];

    let aboutPosition = headerHeight + heroHeight;
    let projectsPosition =
      aboutPosition +
      aboutHeight +
      projectHeadingHeight +
      projectHeadingUnderline;

    let omdbHeight = document.getElementsByClassName("project")[0].offsetHeight;
    let mountadexPosition = projectsPosition + omdbHeight;
    let mountadexHeight = document.getElementsByClassName("project")[1]
      .offsetHeight;
    let eventPosition = mountadexPosition + mountadexHeight;
    let eventHeight = document.getElementsByClassName("project")[2]
      .offsetHeight;
    let checkPosition = eventPosition + eventHeight + 200;

    let checkHeight = document.getElementsByClassName("project")[3]
      .offsetHeight;
    let dogPosition = checkPosition + checkHeight;

    let dogHeight = document.getElementsByClassName("project")[4].offsetHeight;
    let brainflixPosition = dogPosition + dogHeight;

    let brainflixHeight = document.getElementsByClassName("project")[5]
      .offsetHeight;
    let dragCityPosition = brainflixPosition + brainflixHeight;

    if (project === "omdb") {
      if (initialSize === "mobile") {
        allProjects[1].scrollIntoView();
      } else {
        if (this.state.viewWidth <= 1024) {
          mountadexPosition = mountadexPosition + 250;
        } else if (
          this.state.viewWidth <= 1440 &&
          this.state.viewWidth >= 1280
        ) {
          mountadexPosition = mountadexPosition + 200;
        } else {
          mountadexPosition = mountadexPosition + 240;
        }
        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${mountadexPosition * -1}px, 0px)`
        );
        const serialNumber = this.state.updateNumber + 1;
        this.setState({
          scrollPosition: mountadexPosition * -4,
          updateNumber: serialNumber,
        });
      }
    } else if (project === "mountadex") {
      if (initialSize === "mobile") {
        allProjects[2].scrollIntoView();
      } else {
        if (this.state.viewWidth <= 1024) {
          eventPosition = eventPosition + 380;
        } else if (
          this.state.viewWidth <= 1440 &&
          this.state.viewWidth >= 1280
        ) {
          eventPosition = eventPosition + 400;
        } else {
          eventPosition = eventPosition + 440;
        }
        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${eventPosition * -1}px, 0px)`
        );
        const serialNumber = this.state.updateNumber + 1;
        this.setState({
          scrollPosition: eventPosition * -4,
          updateNumber: serialNumber,
        });
      }
    } else if (project === "event") {
      if (initialSize === "mobile") {
        allProjects[3].scrollIntoView();
      } else {
        if (this.state.viewWidth <= 1024) {
          checkPosition = checkPosition + 350;
        } else if (
          this.state.viewWidth <= 1440 &&
          this.state.viewWidth >= 1280
        ) {
          checkPosition = checkPosition + 350;
        } else {
          checkPosition = checkPosition + 400;
        }
        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${checkPosition * -1}px, 0px)`
        );
        const serialNumber = this.state.updateNumber + 1;
        this.setState({
          scrollPosition: checkPosition * -4,
          updateNumber: serialNumber,
        });
      }
    } else if (project === "check") {
      if (initialSize === "mobile") {
        allProjects[4].scrollIntoView();
      } else {
        if (this.state.viewWidth <= 1024) {
          dogPosition = dogPosition + 490;
        } else if (
          this.state.viewWidth <= 1440 &&
          this.state.viewWidth >= 1280
        ) {
          dogPosition = dogPosition + 500;
        } else {
          dogPosition = dogPosition + 520;
        }
        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${dogPosition * -1}px, 0px)`
        );
        const serialNumber = this.state.updateNumber + 1;
        this.setState({
          scrollPosition: dogPosition * -4,
          updateNumber: serialNumber,
        });
      }
    } else if (project === "dog") {
      if (initialSize === "mobile") {
        allProjects[5].scrollIntoView();
      } else {
        if (this.state.viewWidth <= 1024) {
          brainflixPosition = brainflixPosition + 650;
        } else if (
          this.state.viewWidth <= 1440 &&
          this.state.viewWidth >= 1280
        ) {
          brainflixPosition = brainflixPosition + 670;
        } else {
          brainflixPosition = brainflixPosition + 700;
        }
        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${brainflixPosition * -1}px, 0px)`
        );
        const serialNumber = this.state.updateNumber + 1;
        this.setState({
          scrollPosition: brainflixPosition * -4,
          updateNumber: serialNumber,
        });
      }
    } else if (project === "brainflix") {
      if (initialSize === "mobile") {
        allProjects[6].scrollIntoView();
      } else {
        if (this.state.viewWidth <= 1024) {
          dragCityPosition = dragCityPosition + 800;
        } else if (
          this.state.viewWidth <= 1440 &&
          this.state.viewWidth >= 1280
        ) {
          dragCityPosition = dragCityPosition + 820;
        } else {
          dragCityPosition = dragCityPosition + 830;
        }
        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${dragCityPosition * -1}px, 0px)`
        );
        const serialNumber = this.state.updateNumber + 1;
        this.setState({
          scrollPosition: dragCityPosition * -4,
          updateNumber: serialNumber,
        });
      }
    } else if (project === "dragcity") {
      if (initialSize === "mobile") {
        skillsSection.scrollIntoView();
        button.className = "skip-button skip-button--hide";

        if (this.state.viewWidth >= 768) {
          const images = document.getElementsByClassName("skills__image");
          images[8].classList.add("skills__image--javaScript-animate");
          images[images.length - 1].onanimationend = () => {
            images[0].classList.add("skills__image--react-animate");
            images[1].classList.add("skills__image--HTML-animate");
            images[2].classList.add("skills__image--sass-animate");
            images[3].classList.add("skills__image--git-animate");
            images[4].classList.add("skills__image--native-animate");
            images[5].classList.add("skills__image--express-animate");
            images[6].classList.add("skills__image--node-animate");
            images[7].classList.add("skills__image--firebase-animate");
          };
          images[7].onanimationend = () => {
            for (let i = 0; i < images.length; i++) {
              images[i].setAttribute("style", "opacity:1");
            }
          };
        }
      } else {
        const projectsHeight = document.getElementsByClassName(
          "projects-section"
        )[0].offsetHeight;
        const skillsPosition = aboutPosition + aboutHeight + projectsHeight;
        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${skillsPosition * -1}px, 0px)`
        );
        const serialNumber = this.state.updateNumber + 1;
        this.setState({
          scrollPosition: skillsPosition * -4,
          updateNumber: serialNumber,
        });
        home.className = "home home--background-skills";
        const button = document.getElementsByClassName("skip-button")[0];
        button.className = "skip-button skip-button--hide";
        if (this.props.viewWidth < 768) {
          const skillsPosition = (this.state.skillsPosition - 175) * -4;
          const serialNumber = this.state.updateNumber + 1;
          this.setState({
            updateNumber: serialNumber,
            scrollPosition: skillsPosition,
          });
          home.setAttribute(
            "style",
            `transform: translate3d(0px, ${skillsPosition / 4}px, 0px)`
          );
        } else {
          const skillsPosition = (this.state.skillsPosition - 160) * -4;
          const images = document.getElementsByClassName("skills__image");
          images[8].classList.add("skills__image--javaScript-animate");
          images[images.length - 1].onanimationend = () => {
            images[0].classList.add("skills__image--react-animate");
            images[1].classList.add("skills__image--HTML-animate");
            images[2].classList.add("skills__image--sass-animate");
            images[3].classList.add("skills__image--git-animate");
            images[4].classList.add("skills__image--native-animate");
            images[5].classList.add("skills__image--express-animate");
            images[6].classList.add("skills__image--node-animate");
            images[7].classList.add("skills__image--firebase-animate");
          };
          images[7].onanimationend = () => {
            for (let i = 0; i < images.length; i++) {
              images[i].setAttribute("style", "opacity:1");
            }
          };
          home.setAttribute(
            "style",
            `transform: translate3d(0px, ${skillsPosition / 4}px, 0px)`
          );
          const serialNumber = this.state.updateNumber + 1;
          this.setState({
            updateNumber: serialNumber,
            scrollPosition: skillsPosition,
          });
        }
      }
    }
  };

  render() {
    return (
      <>
        {this.state.loaded ? (
          <Landing landingToggle={this.landingToggle} />
        ) : null}
        <HomePage
          loaded={this.state.loaded}
          landingComplete={this.state.landingComplete}
          projectLocation={this.projectLocation}
          scrollPosition={this.state.scrollPosition}
          updateNumber={this.state.updateNumber}
          viewWidth={this.state.viewWidth}
          viewHeight={this.state.viewHeight}
          aboutPosition={this.state.aboutPosition}
          projectsPosition={this.state.projectsPosition}
          skillsPosition={this.state.skillsPosition}
          contactPosition={this.state.contactPosition}
          initialSize={initialSize}
        />
        <button
          onClick={this.skipHandler}
          className="skip-button skip-button--hide"
          aria-label="skip to next project"
        >
          {" "}
          <svg
            className="skip-arrow"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 490.688 490.688"
            xmlSpace="preserve"
          >
            <g>
              <path
                d="M472.328,216.529L245.213,443.665L18.098,216.529c-4.237-4.093-10.99-3.975-15.083,0.262
		c-3.992,4.134-3.992,10.687,0,14.82l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0l234.667-234.667
		c4.093-4.237,3.975-10.99-0.262-15.083c-4.134-3.993-10.687-3.993-14.821,0L472.328,216.529z"
              />
              <path
                d="M472.328,24.529L245.213,251.665L18.098,24.529c-4.237-4.093-10.99-3.975-15.083,0.262
		c-3.992,4.134-3.992,10.687,0,14.821l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0L487.432,39.612
		c4.237-4.093,4.354-10.845,0.262-15.083c-4.093-4.237-10.845-4.354-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262
		L472.328,24.529z"
              />
            </g>
            <path
              d="M245.213,469.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,231.633c-4.093-4.237-3.975-10.99,0.262-15.083
	c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.237-4.093,10.99-3.975,15.083,0.262
	c3.993,4.134,3.993,10.687,0,14.821L252.744,466.279C250.748,468.28,248.04,469.408,245.213,469.415z"
            />
            <path
              d="M245.213,277.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,39.633c-4.093-4.237-3.975-10.99,0.262-15.083
	c4.134-3.992,10.687-3.992,14.821,0l227.136,227.115L472.328,24.529c4.093-4.237,10.845-4.354,15.083-0.262
	s4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,274.279C250.748,276.28,248.04,277.408,245.213,277.415z"
            />
          </svg>
        </button>
      </>
    );
  }
}
