import React from "react";

import "./Landing.scss";

export default class Landing extends React.Component {
  callNextAnimation = () => {
    const landing = document.getElementsByClassName("landing")[0];
    landing.classList.add("landing--animate");
  };

  callHeroAnimations = () => {
    const fullStack = document.getElementsByClassName("hero__full")[0];
    fullStack.classList.add("hero__full--animate");

    const based = document.getElementsByClassName("hero__based")[0];
    based.classList.add("hero__based--animate");

    const location = document.getElementsByClassName("hero__location")[0];
    location.classList.add("hero__location--animate");

    const initialImage = document.getElementsByClassName("hero__image");
    initialImage[0].classList.add("hero__image--initial");
    initialImage[1].classList.add("hero__image--initial-one");
    initialImage[2].classList.add("hero__image--initial-two");

    setTimeout(() => {
      initialImage[0].classList.remove("hero__image--initial");
      initialImage[1].classList.remove("hero__image--initial-one");
      initialImage[2].classList.remove("hero__image--initial-two");
    }, 7000);

    const logo = document.getElementsByClassName("header__logo")[0];
    logo.classList.add("header__logo--animate");

    const menu = document.getElementsByClassName("header__menu")[0];
    menu.classList.add("header__menu--animate");

    const header = document.getElementsByClassName("header")[0];
    setTimeout(() => {
      header.classList.add("header--black");
    }, 2000);

    this.props.landingToggle();
  };

  render() {
    return (
      <section className="landing" onAnimationEnd={this.callHeroAnimations}>
        <div className="landing__name-container">
          <div
            className="landing__first"
            onAnimationEnd={this.callNextAnimation}
          >
            Roy
          </div>
          <div className="landing__last">Lee</div>
        </div>
      </section>
    );
  }
}
