import React from "react";

//componenets
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import About from "../../components/About/About";
import AllProjects from "../../components/AllProjects/AllProjects";
import Skills from "../../components/Skills/Skills";
import Contact from "../../components/Contact/Contact";

//styles
import "./HomePage.scss";

let scrollPosition = 0;
let ratio = 0;
let scrollListener = false;
let lastPosition = 0;
export default class HomePage extends React.Component {
  state = {
    headerOpen: false,
    showHeaderTop: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.scrollToTop();
    const home = document.getElementsByClassName("home")[0];
    const homeHeight = (home.clientHeight - this.props.viewHeight) * 4;
    ratio = homeHeight / (scrollPosition * -1);
    const body = document.querySelector("body");
    const lastItem = document.getElementsByClassName("hero__location")[0];

    if (this.props.viewWidth <= 1024) {
      if (this.props.viewWidth === 1024 && this.props.viewHeight > 1300) {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("beforeunload", () => {
          window.scrollTo(0, 0);
          window.removeEventListener("scroll", this.handleScroll);
        });
        lastItem.onanimationend = () => {
          body.classList.remove("body");
        };
      } else if (this.props.viewWidth < 1024) {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("beforeunload", () => {
          window.scrollTo(0, 0);
          window.removeEventListener("scroll", this.handleScroll);
        });
        lastItem.onanimationend = () => {
          body.classList.remove("body");
        };
      } else {
        setTimeout(() => {
          window.addEventListener("wheel", this.slowScroll);
        }, 5000);
        scrollListener = true;
        window.addEventListener("beforeunload", () => {
          this.scrollToTop("unload");
        });
      }
    } else {
      setTimeout(() => {
        window.addEventListener("wheel", this.slowScroll);
      }, 5000);
      scrollListener = true;
      window.addEventListener("beforeunload", () => {
        this.scrollToTop("unload");
      });
    }
  }

  componentDidUpdate(prevProps) {
    const body = document.querySelector("body");
    if (this.props.updateNumber !== prevProps.updateNumber) {
      scrollPosition = this.props.scrollPosition;
    }
    if (this.props.viewWidth !== prevProps.viewWidth) {
      const home = document.getElementsByClassName("home")[0];
      const homeHeight = (home.clientHeight - this.props.viewHeight) * 4;
      if (this.props.viewWidth <= 1024) {
        window.addEventListener("beforeunload", () => {
          this.scrollToTop();
        });
        if (this.props.viewWidth === 1024 && this.props.viewHeight > 1200) {
        } else if (this.props.viewWidth < 1024) {
        } else {
          if (!scrollListener) {
            window.addEventListener("wheel", this.slowScroll);
            scrollListener = true;
          }
          if (!body.className.includes("body")) {
            body.classList.add("body");
            body.classList.add("body--fixed");
          }
          this.scrollToTop();
          if (ratio > 0) {
            scrollPosition = (homeHeight / ratio) * -1;
            home.setAttribute(
              "style",
              `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
            );
          }
        }
      } else {
        if (!scrollListener) {
          window.addEventListener("wheel", this.slowScroll);
        }
        if (!body.className.includes("body")) {
          body.classList.add("body");
          body.classList.add("body--fixed");
        }
        this.scrollToTop();
        window.addEventListener("beforeunload", () => {
          this.scrollToTop();
        });
        if (ratio > 0) {
          scrollPosition = (homeHeight / ratio) * -1;
          home.setAttribute(
            "style",
            `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
          );
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("wheel", this.slowScroll);
    window.removeEventListener("resize", this.size);
    window.removeEventListener("beforeunload");
    this.scrollToTop();
  }

  handleScroll = () => {
    const header = document.getElementsByClassName("header")[0];
    if (window.scrollY < lastPosition) {
      header.classList.remove("header--top");
    } else {
      header.classList.add("header--top");
    }
    lastPosition = window.scrollY;
  };

  slowScroll = (event) => {
    const home = document.getElementsByClassName("home")[0];
    const homeHeight = (home.clientHeight - this.props.viewHeight) * 4;
    const header = document.getElementsByClassName("header")[0];
    if (event.deltaMode === 0) {
      if (this.state.headerOpen) {
      } else {
        if (scrollPosition === 0 && event.deltaY > 0) {
          this.setState({
            showHeaderTop: true,
          });
          header.classList.remove("header--top");
        }
        if (
          scrollPosition <= 0 &&
          scrollPosition * -1 < homeHeight &&
          event.deltaY > 0
        ) {
          scrollPosition += event.deltaY * -1;
          home.setAttribute(
            "style",
            `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
          );
          if (this.state.showHeaderTop) {
            this.setState({
              showHeaderTop: false,
            });
            header.classList.add("header--top");
          }
        } else if (scrollPosition >= 0 && event.deltaY < 0) {
          scrollPosition = 0;
          home.setAttribute("style", `transform: translate3d(0px, 0px, 0px)`);
        } else if (scrollPosition * -1 >= homeHeight && event.deltaY > 0) {
          scrollPosition = homeHeight * -1;
          home.setAttribute(
            "style",
            `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
          );
        } else {
          scrollPosition += event.deltaY * -1;
          home.setAttribute(
            "style",
            `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
          );
          if (!this.state.showHeaderTop) {
            this.setState({
              showHeaderTop: true,
            });
            header.classList.remove("header--top");
          }
        }
      }
    } else {
      if (this.state.headerOpen) {
      } else {
        if (scrollPosition === 0 && event.deltaY > 0) {
          this.setState({
            showHeaderTop: true,
          });
          header.classList.remove("header--top");
        }
        if (
          scrollPosition <= 0 &&
          scrollPosition * -1 < homeHeight &&
          event.deltaY > 0
        ) {
          scrollPosition += event.deltaY * -20;
          home.setAttribute(
            "style",
            `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
          );
          if (this.state.showHeaderTop) {
            this.setState({
              showHeaderTop: false,
            });
            header.classList.add("header--top");
          }
        } else if (scrollPosition >= 0 && event.deltaY < 0) {
          scrollPosition = 0;
          home.setAttribute("style", `transform: translate3d(0px, 0px, 0px)`);
        } else if (scrollPosition * -1 >= homeHeight && event.deltaY > 0) {
          scrollPosition = homeHeight * -1;
          home.setAttribute(
            "style",
            `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
          );
        } else {
          scrollPosition += event.deltaY * -20;
          home.setAttribute(
            "style",
            `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
          );
          if (!this.state.showHeaderTop) {
            this.setState({
              showHeaderTop: true,
            });
            header.classList.remove("header--top");
          }
        }
      }
    }

    ratio = homeHeight / (scrollPosition * -1);
  };

  toggleHeaderState = () => {
    this.setState({
      headerOpen: !this.state.headerOpen,
    });
  };

  scrollToTop = (content) => {
    window.scrollTo(0, 0);
    const home = document.getElementsByClassName("home")[0];
    scrollPosition = 0;
    home.setAttribute("style", `transform: translate3d(0px, 0px, 0px)`);
    const button = document.getElementsByClassName("skip-button")[0];
    if (button.className.includes("animate")) {
      button.className = "skip-button";
    }
    if (content === "unload") {
      window.removeEventListener("wheel", this.slowScroll);
    }
  };

  headerScroll = (section) => {
    const home = document.getElementsByClassName("home")[0];
    const button = document.getElementsByClassName("skip-button")[0];
    const contactHeading = document.getElementsByClassName(
      "contact__heading"
    )[0];
    const contactUnderline = document.getElementsByClassName(
      "contact__heading-underline"
    )[0];

    const about = document.getElementsByClassName("about-section")[0];
    const projects = document.getElementsByClassName("projects-section")[0];
    const skills = document.getElementsByClassName("skills-section")[0];
    const contact = document.getElementsByClassName("contact-section")[0];

    if (this.props.viewWidth === 1024 && this.props.viewHeight > 1300) {
      if (section === "home") {
        window.scrollTo(0, 0);
        home.className = "home home--background-black";
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
      } else if (section === "about") {
        about.scrollIntoView();
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
      } else if (section === "projects") {
        projects.scrollIntoView();
        home.className = "home home--background--mountadex";
        if (!button.className.includes("animate")) {
          button.className = "skip-button skip-button--animate";
        }
      } else if (section === "skills") {
        skills.scrollIntoView();
        home.className = "home home--background-skills";
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
      } else if (section === "contact") {
        contact.scrollIntoView();
        home.className = "home home--background-white";
        contactHeading.classList.add("contact__heading--black");
        contactUnderline.classList.add("contact__heading-underline--black");
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
      }
    }

    if (this.props.initialSize === "mobile") {
      if (section === "home") {
        window.scrollTo(0, 0);
        home.className = "home home--background-black";
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
      } else if (section === "about") {
        about.scrollIntoView();
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
      } else if (section === "projects") {
        projects.scrollIntoView();
        home.className = "home home--background--mountadex";
        if (!button.className.includes("animate")) {
          button.className = "skip-button skip-button--animate";
        }
      } else if (section === "skills") {
        skills.scrollIntoView();
        home.className = "home home--background-skills";
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
      } else if (section === "contact") {
        contact.scrollIntoView();
        home.className = "home home--background-white";
        contactHeading.classList.add("contact__heading--black");
        contactUnderline.classList.add("contact__heading-underline--black");
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
      }
    } else {
      if (section === "home") {
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
        scrollPosition = 0;
        home.setAttribute("style", `transform: translate3d(0px, 0px, 0px)`);
        home.className = "home home--background-black";
      } else if (section === "about") {
        if (this.props.viewWidth < 768) {
          scrollPosition = (this.props.aboutPosition - 100) * -4;
        } else {
          scrollPosition = this.props.aboutPosition * -4;
        }
        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
        );
      } else if (section === "projects") {
        if (!button.className.includes("animate")) {
          button.className = "skip-button skip-button--animate";
        }
        scrollPosition = this.props.projectsPosition * -4;
        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
        );
        home.className = "home home--background--mountadex";
      } else if (section === "skills") {
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
        home.className = "home home--background-skills";
        if (this.props.viewWidth < 768) {
          scrollPosition = (this.props.skillsPosition - 175) * -4;
        } else {
          scrollPosition = (this.props.skillsPosition - 160) * -4;
          const images = document.getElementsByClassName("skills__image");
          images[8].classList.add("skills__image--javaScript-animate");
          images[images.length - 1].onanimationend = () => {
            images[0].classList.add("skills__image--react-animate");
            images[1].classList.add("skills__image--HTML-animate");
            images[2].classList.add("skills__image--sass-animate");
            images[3].classList.add("skills__image--git-animate");
            images[4].classList.add("skills__image--native-animate");
            images[5].classList.add("skills__image--express-animate");
            images[6].classList.add("skills__image--node-animate");
            images[7].classList.add("skills__image--firebase-animate");
          };
          images[7].onanimationend = () => {
            for (let i = 0; i < images.length; i++) {
              images[i].setAttribute("style", "opacity:1");
            }
          };
        }

        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
        );
      } else if (section === "contact") {
        home.className = "home home--background-white";
        contactHeading.classList.add("contact__heading--black");
        contactUnderline.classList.add("contact__heading-underline--black");
        if (button.className.includes("animate")) {
          button.className = "skip-button skip-button--hidden";
        }
        if (this.props.viewWidth < 1024) {
          scrollPosition = this.props.contactPosition * -4;
        } else {
          scrollPosition = (this.props.contactPosition + 50) * -4;
        }
        home.setAttribute(
          "style",
          `transform: translate3d(0px, ${scrollPosition / 4}px, 0px)`
        );
      }
    }
  };

  render() {
    return (
      <>
        <Header
          headerScroll={this.headerScroll}
          toggleHeaderState={this.toggleHeaderState}
          scrollToTop={this.scrollToTop}
        />
        <div className="home home--background-black">
          <div className="hero-section" id="hero-section">
            <Hero
              landingComplete={this.props.landingComplete}
              viewWidth={this.props.viewWidth}
              viewHeight={this.props.viewHeight}
            />
          </div>
          <div className="about-section" id="about-section">
            <About
              viewWidth={this.props.viewWidth}
              viewHeight={this.props.viewHeight}
            />
          </div>
          <div className="projects-section" id="projects-section">
            <AllProjects
              viewWidth={this.props.viewWidth}
              viewHeight={this.props.viewHeight}
              projectLocation={this.props.projectLocation}
            />
          </div>
          <div className="skills-section" id="skills-section">
            <Skills
              viewWidth={this.props.viewWidth}
              viewHeight={this.props.viewHeight}
            />
          </div>
          <div className="contact-section">
            <Contact
              viewWidth={this.props.viewWidth}
              viewHeight={this.props.viewHeight}
            />
          </div>
        </div>
      </>
    );
  }
}
