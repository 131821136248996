import React from "react";

import OmdbLaptop from "../../assets/projects/omdb-movies/omdb-movies-combined-macbook.png";
import OmdbTablet from "../../assets/projects/omdb-movies/omdb-movies-combined-ipad.png";
import OmdbPhone from "../../assets/projects/omdb-movies/omdb-movies-combined-iphonex.png";

import MountadexLaptop from "../../assets/projects/mountadex/mountadex-combined-macbook.png";
import MountadexPhone from "../../assets/projects/mountadex/mountadex-combined-iphonex.png";

import CheckItOffRight from "../../assets/projects/check-it-off/checkitoff-macbook-right-dashboard.png";
import CheckItOffCenter from "../../assets/projects/check-it-off/checkitoff-macbook-center-dashboard.png";

import DawgHawtDawgLaptop from "../../assets/projects/dawg-hawt-dawg/dawghawtdawg-combined-1-macbook.png";
import DawgHawtDawgPhone from "../../assets/projects/dawg-hawt-dawg/dawghawtdawg-combined-1-iphonex.png";
import DawgHawtDawgTablet from "../../assets/projects/dawg-hawt-dawg/dawghawtdawg-combined-1-ipad.png";

import BrainflixLaptop from "../../assets/projects/brainflix/brainflix-combined-2-macbook.png";
import BrainflixPhone from "../../assets/projects/brainflix/brainflix-combined-2-iphonex.png";
import BrainflixTablet from "../../assets/projects/brainflix/brainflix-combined-2-ipad.png";

import EventFriendsOne from "../../assets/projects/event-friends/two-iphone/event-friends-combined-1-iphonex1.png";
import EventFriendsTwo from "../../assets/projects/event-friends/two-iphone/event-friends-combined-1-iphonex2.png";

import DragCityOne from "../../assets/projects/drag-city/drag-city-combined-iphonex1.png";
import DragCityTwo from "../../assets/projects/drag-city/drag-city-combined-iphonex2.png";
import DragCityThree from "../../assets/projects/drag-city/drag-city-combined-iphonex3.png";

import "./Hero.scss";

let newView = 0;
let changeHeroImage = null;
let initialInterval = false;
export default class Hero extends React.Component {
  componentDidMount() {
    const heroSection = document.getElementsByClassName("hero")[0];
    this.heroSectionObserver.observe(heroSection);
  }

  componentDidUpdate(prevProps) {
    if (this.props.landingComplete !== prevProps.landingComplete) {
      setTimeout(() => {
        this.intervalManager();
      }, 2000);
    }
  }

  checkImage = () => {
    if (this.props.viewWidth >= 1024 && this.props.viewHeight < 1300) {
      return CheckItOffCenter;
    } else {
      return CheckItOffRight;
    }
  };

  heroSectionOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  intervalManager = () => {
    changeHeroImage = setInterval(() => {
      let containers = document.getElementsByClassName(
        "hero__project-containers"
      );
      if (newView === containers.length - 1) {
        containers[newView].classList.add("hero__project-containers--hide");
        containers[0].classList.remove("hero__project-containers--hide");
        newView = 0;
      } else {
        containers[newView].classList.add("hero__project-containers--hide");
        containers[newView + 1].classList.remove(
          "hero__project-containers--hide"
        );
        newView++;
      }
    }, 3000);
    if (!initialInterval) {
      initialInterval = true;
    }
  };

  heroSectionCallback = (entries, options) => {
    let observedEl = entries[0];
    if (observedEl.isIntersecting) {
      if (initialInterval) {
        this.intervalManager();
      }
    } else {
      clearInterval(changeHeroImage);
    }
  };

  heroSectionObserver = new IntersectionObserver(
    this.heroSectionCallback,
    this.heroSectionOptions
  );

  render() {
    return (
      <section className="hero">
        <div className="hero__name-container">
          <div className="hero__heading">
            <span className="hero__first-name">Roy</span>
            <span className="hero__last-name">Lee</span>
          </div>
        </div>
        <div className="hero__projects">
          <div className="hero__project-containers hero__project-containers--brainflix">
            <img
              className="hero__image hero__omdb-image hero__omdb-image--laptop hero__omdb-image--laptop--animation"
              src={OmdbLaptop}
              alt="Omdb movie project macbook screen"
            ></img>
            <img
              className="hero__image hero__omdb-image hero__omdb-image--phone hero__omdb-image--phone--animation"
              src={OmdbTablet}
              alt="Omdb movie project tablet screen"
            ></img>
            <img
              className="hero__image hero__omdb-image hero__omdb-image--tablet hero__omdb-image--tablet--animation"
              src={OmdbPhone}
              alt="Omdb movie project iphone screen"
            ></img>
          </div>
          <div className="hero__project-containers hero__project-containers--mountadex hero__project-containers--hide">
            <img
              className="hero__image hero__mountadex-image hero__mountadex-image--laptop-initial hero__mountadex-image--laptop"
              src={MountadexLaptop}
              alt="Mountadex project laptop screen"
            ></img>
            <img
              className="hero__image hero__mountadex-image hero__mountadex-image--phone-initial hero__mountadex-image--phone"
              src={MountadexPhone}
              alt="Mountadex project iphone screen"
            ></img>
          </div>
          <div className="hero__project-containers hero__project-containers--checkitoff hero__project-containers--hide">
            <img
              className="hero__image hero--checkitoff"
              src={this.checkImage()}
              alt="Checkitoff project laptop screen"
            ></img>
          </div>
          <div className="hero__project-containers hero__project-containers--event hero__project-containers--hide">
            <img
              className="hero__image hero__event-image hero__event-image--one"
              src={EventFriendsOne}
              alt="Event Friends project mobile screen"
            ></img>
            <img
              className="hero__image hero__event-image hero__event-image--two"
              src={EventFriendsTwo}
              alt="Event Friends project mobile screen"
            ></img>
          </div>
          <div className="hero__project-containers hero__project-containers--dawg hero__project-containers--hide">
            <img
              className="hero__image hero__dawg-image hero__dawg-image--laptop"
              src={DawgHawtDawgLaptop}
              alt="Check it off project laptop screen"
            ></img>
            <img
              className="hero__image hero__dawg-image hero__dawg-image--tablet"
              src={DawgHawtDawgTablet}
              alt="Check it off project tablet screen"
            ></img>
            <img
              className="hero__image hero__dawg-image hero__dawg-image--phone"
              src={DawgHawtDawgPhone}
              alt="Check it off project iphone screen"
            ></img>
          </div>
          <div className="hero__project-containers hero__project-containers--brainflix hero__project-containers--hide">
            <img
              className="hero__image hero__brainflix-image hero__brainflix-image--laptop"
              src={BrainflixLaptop}
              alt="Brainflix project macbook screen"
            ></img>
            <img
              className="hero__image hero__brainflix-image hero__brainflix-image--tablet"
              src={BrainflixTablet}
              alt="Brainflix project tablet screen"
            ></img>
            <img
              className="hero__image hero__brainflix-image hero__brainflix-image--phone"
              src={BrainflixPhone}
              alt="Brainflix project iphone screen"
            ></img>
          </div>
          <div className="hero__project-containers hero__project-containers--brainflix hero__project-containers--hide">
            <img
              className="hero__image hero__brainflix-image hero__brainflix-image--laptop"
              src={DragCityOne}
              alt="Brainflix project macbook screen"
            ></img>
            <img
              className="hero__image hero__brainflix-image hero__brainflix-image--phone "
              src={DragCityTwo}
              alt="Brainflix project tablet screen"
            ></img>
            <img
              className="hero__image hero__brainflix-image hero__brainflix-image--tablet"
              src={DragCityThree}
              alt="Brainflix project iphone screen"
            ></img>
          </div>
        </div>
        <div className="hero__container">
          <span className="hero__full">Full Stack Developer</span>
          <div className="hero__location-container">
            <span className="hero__sub-text hero__based">based in</span>
            <span className="hero__sub-text hero__location">
              Toronto, Ontario
            </span>
          </div>
        </div>
        <div className="hero__arrow-container">
          <svg className="hero__arrow" viewBox="0 0 490.688 490.688">
            <path
              d="M472.328,120.529L245.213,347.665L18.098,120.529c-4.237-4.093-10.99-3.975-15.083,0.262
	c-3.992,4.134-3.992,10.687,0,14.82l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0l234.667-234.667
	c4.237-4.093,4.354-10.845,0.262-15.083c-4.093-4.237-10.845-4.354-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262
	L472.328,120.529z"
            />
            <path
              d="M245.213,373.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,135.633c-4.093-4.237-3.975-10.99,0.262-15.083
	c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.093-4.237,10.845-4.354,15.083-0.262
	c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,370.279
	C250.748,372.281,248.039,373.408,245.213,373.415z"
            />
          </svg>
        </div>
      </section>
    );
  }
}
