import React from "react";

import throttle from "../../utility/throttle";

import HTMLIcon from "../../assets/skills/skill-html.png";
import ReactIcon from "../../assets/skills/skill-react.png";
import SassIcon from "../../assets/skills/skill-sass.png";
import JSIcon from "../../assets/skills/skill-javascript.png";
import GitIcon from "../../assets/skills/skill-git.png";
import ReactNativeIcon from "../../assets/skills/skill-react-native.png";
import NodeIcon from "../../assets/skills/skill-node.png";
import ExpressIcon from "../../assets/skills/skill-express.png";
import FirebaseIcon from "../../assets/skills/skill-firebase.png";

import "./Skills.scss";

export default class Skills extends React.Component {
  state = {
    animating: true,
    tech: null,
    projects: null,
    status: false,
  };
  componentDidMount() {
    const container = document.getElementsByClassName(
      "skills__left-container"
    )[0];
    const skillsSection = document.getElementsByClassName("skills")[0];
    this.skillsSectionObserver.observe(skillsSection);
    const images = document.getElementsByClassName("skills__image");
    this.iconObserver0.observe(images[0]);
    this.iconObserver1.observe(images[1]);
    this.iconObserver2.observe(images[2]);
    this.iconObserver3.observe(images[3]);
    this.iconObserver4.observe(images[4]);
    this.iconObserver5.observe(images[5]);
    this.iconObserver6.observe(images[6]);
    this.iconObserver7.observe(images[7]);
    this.iconObserver8.observe(images[8]);

    if (this.props.viewWidth < 768) {
      container.setAttribute(
        "style",
        `height: ${this.props.viewWidth * 1.85}px`
      );
    } else {
      container.removeAttribute("style");
    }

    if (this.props.viewWidth < 768) {
      this.setState({
        animating: false,
      });
    } else {
      const firebase = document.getElementsByClassName(
        "skills__image--firebase"
      )[0];
      firebase.addEventListener("animationend", () => {
        this.setState({
          animating: false,
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewWidth !== prevProps.viewWidth) {
      const container = document.getElementsByClassName(
        "skills__left-container"
      )[0];
      if (this.props.viewWidth < 768) {
        container.setAttribute(
          "style",
          `height: ${this.props.viewWidth * 1.85}px`
        );
      } else {
        container.removeAttribute("style");
      }
    }
  }

  clickHandler = (event, tech) => {
    event.preventDefault();
    const leftContainer = document.getElementsByClassName(
      "skills__left-container"
    )[0];
    const rightContainer = document.getElementsByClassName(
      "skills__right-container"
    )[0];

    if (this.props.viewWidth >= 1024) {
      if (!this.state.status) {
        leftContainer.classList.add("skills__left-container--animate");
        this.setState({
          tech: `${tech === "native" ? "react native" : tech}`,
          projects: this.projects(tech),
          status: true,
        });
        rightContainer.classList.add("skills__right-container--show");
        rightContainer.classList.add("skills__right-container--animate-delay");
        rightContainer.onanimationend = () => {
          rightContainer.classList.remove(
            "skills__right-container--animate-delay"
          );
        };
      } else {
        this.setState({
          tech: `${tech === "native" ? "react native" : tech}`,
          projects: this.projects(tech),
        });
        if (rightContainer.className.includes("animate")) {
          rightContainer.classList.add("skills__right-container--animate");
          rightContainer.onanimationend = () => {
            rightContainer.classList.remove("skills__right-container--animate");
          };
        } else {
          rightContainer.classList.add("skills__right-container--animate");
          rightContainer.onanimationend = () => {
            rightContainer.classList.remove("skills__right-container--animate");
          };
        }
      }
    }
  };

  enterHandler = (event, tech) => {
    if (this.props.viewWidth >= 1024) {
      const images = document.getElementsByClassName("skills__image");
      if (this.state.status) {
        for (let i = 0; i < images.length; i++) {
          if (images[i].className.includes(tech)) {
            images[i].className = `skills__image skills__image--${tech}`;
          } else if (
            images[i].className.includes(
              this.state.tech === "react native" ? "native" : this.state.tech
            )
          ) {
            images[i].classList.add("skills__image--grayscale");
          }
        }
      } else {
        for (let i = 0; i < images.length; i++) {
          if (images[i].className.includes(tech)) {
          } else {
            images[i].classList.add("skills__image--grayscale");
          }
        }
      }
    }
  };

  projects = (tech) => {
    let projects = null;
    if (tech === "react") {
      projects = [
        "OMDb Movies",
        "Mountadex",
        "CheckItOff",
        "DogHotDog",
        "Brainflix",
        "DragCity",
      ];
      return projects;
    } else if (tech === "HTML") {
      return (projects = [
        "OMDb Movies",
        "Mountadex",
        "CheckItOff",
        "DogHotDog",
        "Brainflix",
        "DragCity",
      ]);
    } else if (tech === "sass") {
      return (projects = [
        "OMDb Movies",
        "Mountadex",
        "CheckItOff",
        "DogHotDog",
        "Brainflix",
        "DragCity",
      ]);
    } else if (tech === "git") {
      return (projects = [
        "OMDb Movies",
        "Mountadex",
        "EventFriends",
        "CheckItOff",
        "DogHotDog",
        "Brainflix",
        "DragCity",
      ]);
    } else if (tech === "native") {
      return (projects = ["EventFriends"]);
    } else if (tech === "javaScript") {
      return (projects = [
        "OMDb Movies",
        "Mountadex",
        "EventFriends",
        "CheckItOff",
        "DogHotDog",
        "Brainflix",
        "DragCity",
      ]);
    } else if (tech === "node") {
      return (projects = ["OMDb Movies", "EventFriends", "Brainflix"]);
    } else if (tech === "express") {
      return (projects = ["OMDb Movies", "EventFriends", "Brainflix"]);
    } else if (tech === "firebase") {
      return (projects = ["DogHotdog"]);
    }
  };

  leaveHandler = (event, tech) => {
    if (this.props.viewWidth >= 1024) {
      const images = document.getElementsByClassName("skills__image");
      if (this.state.status) {
        for (let i = 0; i < images.length; i++) {
          if (
            images[i].className.includes(
              this.state.tech === "react native" ? "native" : this.state.tech
            )
          ) {
            images[i].className = `skills__image skills__image--${
              this.state.tech === "react native" ? "native" : this.state.tech
            }`;
          } else {
            if (images[i].className.includes(tech)) {
              images[i].classList.add("skills__image--grayscale");
            }
          }
        }
      } else {
        for (let i = 0; i < images.length; i++) {
          images[i].classList.remove("skills__image--grayscale");
        }
      }
    }
  };

  skillsSectionOptions = {
    root: null,
    rootMargin: "0px",
    threshold: [0, 0.4, 0.5, 0.6, 1],
  };

  skillsSectionCallback = (entries, options) => {
    const observedEl = entries[0];
    const homePage = document.getElementsByClassName("home")[0];
    const images = document.getElementsByClassName("skills__image");
    const leftContainer = document.getElementsByClassName(
      "skills__left-container"
    )[0];
    const rightContainer = document.getElementsByClassName(
      "skills__right-container"
    )[0];
    const skipButton = document.getElementsByClassName("skip-button")[0];
    if (this.props.viewWidth >= 1024 && this.props.viewHeight > 1000) {
      if (
        observedEl.isIntersecting &&
        observedEl.intersectionRatio === 1 &&
        observedEl.target.getBoundingClientRect().top < 100
      ) {
        homePage.classList.add("home--background-skills");
        skipButton.className = "skip-button skip-button--hide";
      }
    }
    if (
      observedEl.isIntersecting &&
      observedEl.intersectionRatio > 0.4 &&
      observedEl.intersectionRatio < 0.6
    ) {
      if (this.props.viewWidth >= 1024 && this.props.viewHeight > 1000) {
        skipButton.className = "skip-button skip-button--hide";
        if (observedEl.target.getBoundingClientRect().top < 0) {
        } else {
          homePage.classList.add("home--background-skills");
          skipButton.className = "skip-button skip-button--hide";
        }
      } else {
        homePage.classList.add("home--background-skills");
        skipButton.className = "skip-button skip-button--hide";
      }
    } else if (
      observedEl.isIntersecting &&
      observedEl.intersectionRatio > 0.6 &&
      observedEl.intersectionRatio < 0.7
    ) {
      if (this.props.viewWidth >= 768) {
        skipButton.className = "skip-button skip-button--hide";
        if (!images[8].className.includes("animate") && !this.state.status) {
          images[8].classList.add("skills__image--javaScript-animate");
          images[images.length - 1].onanimationend = () => {
            images[0].classList.add("skills__image--react-animate");
            images[1].classList.add("skills__image--HTML-animate");
            images[2].classList.add("skills__image--sass-animate");
            images[3].classList.add("skills__image--git-animate");
            images[4].classList.add("skills__image--native-animate");
            images[5].classList.add("skills__image--express-animate");
            images[6].classList.add("skills__image--node-animate");
            images[7].classList.add("skills__image--firebase-animate");
          };
        }
        images[7].onanimationend = () => {
          for (let i = 0; i < images.length; i++) {
            images[i].setAttribute("style", "opacity:1");
          }
        };
      }
    } else if (!observedEl.isIntersecting) {
      homePage.classList.remove("home--background-skills");
      images[8].classList.remove("skills__image--javaScript-animate");
      images[0].classList.remove("skills__image--react-animate");
      images[1].classList.remove("skills__image--HTML-animate");
      images[2].classList.remove("skills__image--sass-animate");
      images[3].classList.remove("skills__image--git-animate");
      images[4].classList.remove("skills__image--native-animate");
      images[5].classList.remove("skills__image--express-animate");
      images[6].classList.remove("skills__image--node-animate");
      images[7].classList.remove("skills__image--firebase-animate");
      for (let i = 0; i < images.length; i++) {
        images[i].removeAttribute("style");
      }
      leftContainer.classList.remove("skills__left-container--animate");
      rightContainer.classList.remove("skills__right-container--show");
      rightContainer.classList.remove("skills__right-container--animate-delay");
      this.setState({
        tech: null,
        projects: null,
        status: false,
        animating: true,
      });
    }
  };

  iconOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  iconCallback = (entries, options) => {
    const observedEl = entries[0];
    if (this.props.viewWidth < 768) {
      if (observedEl.isIntersecting) {
        observedEl.target.classList.add("skills__image--mobile-animate");
      } else {
        observedEl.target.classList.remove("skills__image--mobile-animate");
      }
    }
  };

  skillsSectionObserver = new IntersectionObserver(
    this.skillsSectionCallback,
    this.skillsSectionOptions
  );

  iconObserver0 = new IntersectionObserver(
    throttle(100, this.iconCallback),
    this.iconOptions
  );
  iconObserver1 = new IntersectionObserver(
    throttle(100, this.iconCallback),
    this.iconOptions
  );
  iconObserver2 = new IntersectionObserver(
    throttle(100, this.iconCallback),
    this.iconOptions
  );
  iconObserver3 = new IntersectionObserver(
    throttle(100, this.iconCallback),
    this.iconOptions
  );
  iconObserver4 = new IntersectionObserver(
    throttle(100, this.iconCallback),
    this.iconOptions
  );
  iconObserver5 = new IntersectionObserver(
    throttle(100, this.iconCallback),
    this.iconOptions
  );
  iconObserver6 = new IntersectionObserver(
    throttle(100, this.iconCallback),
    this.iconOptions
  );
  iconObserver7 = new IntersectionObserver(
    throttle(100, this.iconCallback),
    this.iconOptions
  );
  iconObserver8 = new IntersectionObserver(
    throttle(100, this.iconCallback),
    this.iconOptions
  );

  render() {
    return (
      <section className="skills">
        <h2 className="skills__heading">Skills</h2>
        <div className="skills__underline"></div>
        <div className="skills__container">
          <div className="skills__left-container">
            <img
              onClick={
                this.state.animating
                  ? null
                  : (event) => this.clickHandler(event, "react")
              }
              onMouseEnter={
                this.state.animating
                  ? null
                  : (event) => this.enterHandler(event, "react")
              }
              onMouseLeave={
                this.state.animating
                  ? null
                  : (event) => this.leaveHandler(event, "react")
              }
              className={`${
                this.state.status && this.state.tech !== "react"
                  ? "skills__image--grayscale"
                  : ""
              } skills__image skills__image--react`}
              src={ReactIcon}
              alt="React logo"
            />
            <img
              onClick={
                this.state.animating
                  ? null
                  : (event) => this.clickHandler(event, "HTML")
              }
              onMouseEnter={
                this.state.animating
                  ? null
                  : (event) => this.enterHandler(event, "HTML")
              }
              onMouseLeave={
                this.state.animating
                  ? null
                  : (event) => this.leaveHandler(event, "HTML")
              }
              className={`${
                this.state.status && this.state.tech !== "HTML"
                  ? "skills__image--grayscale"
                  : ""
              } skills__image skills__image--HTML`}
              src={HTMLIcon}
              alt="HTML logo"
            />
            <img
              onClick={
                this.state.animating
                  ? null
                  : (event) => this.clickHandler(event, "sass")
              }
              onMouseEnter={
                this.state.animating
                  ? null
                  : (event) => this.enterHandler(event, "sass")
              }
              onMouseLeave={
                this.state.animating
                  ? null
                  : (event) => this.leaveHandler(event, "sass")
              }
              className={`${
                this.state.status && this.state.tech !== "sass"
                  ? "skills__image--grayscale"
                  : ""
              } skills__image skills__image--sass`}
              src={SassIcon}
              alt="Sass logo"
            />
            <img
              onClick={
                this.state.animating
                  ? null
                  : (event) => this.clickHandler(event, "git")
              }
              onMouseEnter={
                this.state.animating
                  ? null
                  : (event) => this.enterHandler(event, "git")
              }
              onMouseLeave={
                this.state.animating
                  ? null
                  : (event) => this.leaveHandler(event, "git")
              }
              className={`${
                this.state.status && this.state.tech !== "git"
                  ? "skills__image--grayscale"
                  : ""
              } skills__image skills__image--git`}
              src={GitIcon}
              alt="Git Logo"
            />
            <img
              onClick={
                this.state.animating
                  ? null
                  : (event) => this.clickHandler(event, "native")
              }
              onMouseEnter={
                this.state.animating
                  ? null
                  : (event) => this.enterHandler(event, "native")
              }
              onMouseLeave={
                this.state.animating
                  ? null
                  : (event) => this.leaveHandler(event, "native")
              }
              className={`${
                this.state.status && this.state.tech !== "react native"
                  ? "skills__image--grayscale"
                  : ""
              } skills__image skills__image--native`}
              src={ReactNativeIcon}
              alt="React Native logo"
            />
            <img
              onClick={
                this.state.animating
                  ? null
                  : (event) => this.clickHandler(event, "express")
              }
              onMouseEnter={
                this.state.animating
                  ? null
                  : (event) => this.enterHandler(event, "express")
              }
              onMouseLeave={
                this.state.animating
                  ? null
                  : (event) => this.leaveHandler(event, "express")
              }
              className={`${
                this.state.status && this.state.tech !== "express"
                  ? "skills__image--grayscale"
                  : ""
              } skills__image skills__image--express`}
              src={ExpressIcon}
              alt="Express logo"
            />
            <img
              onClick={
                this.state.animating
                  ? null
                  : (event) => this.clickHandler(event, "node")
              }
              onMouseEnter={
                this.state.animating
                  ? null
                  : (event) => this.enterHandler(event, "node")
              }
              onMouseLeave={
                this.state.animating
                  ? null
                  : (event) => this.leaveHandler(event, "node")
              }
              className={`${
                this.state.status && this.state.tech !== "node"
                  ? "skills__image--grayscale"
                  : ""
              } skills__image skills__image--node`}
              src={NodeIcon}
              alt="Node Logo"
            />
            <img
              onClick={
                this.state.animating
                  ? null
                  : (event) => this.clickHandler(event, "firebase")
              }
              onMouseEnter={
                this.state.animating
                  ? null
                  : (event) => this.enterHandler(event, "firebase")
              }
              onMouseLeave={
                this.state.animating
                  ? null
                  : (event) => this.leaveHandler(event, "firebase")
              }
              className={`${
                this.state.status && this.state.tech !== "firebase"
                  ? "skills__image--grayscale"
                  : ""
              } skills__image skills__image--firebase`}
              src={FirebaseIcon}
              alt="Firebase logo"
            />
            <img
              onClick={
                this.state.animating
                  ? null
                  : (event) => this.clickHandler(event, "javaScript")
              }
              onMouseEnter={
                this.state.animating
                  ? null
                  : (event) => this.enterHandler(event, "javaScript")
              }
              onMouseLeave={
                this.state.animating
                  ? null
                  : (event) => this.leaveHandler(event, "javaScript")
              }
              className={`${
                this.state.status && this.state.tech !== "javaScript"
                  ? "skills__image--grayscale"
                  : ""
              } skills__image skills__image--javaScript`}
              src={JSIcon}
              alt="Javascript logo"
            />
          </div>
          <div className="skills__right-container">
            <h3
              className={`skills__tech ${
                this.state.tech ? `skills__tech--${this.state.tech}` : null
              }`}
            >
              {this.state.tech}
            </h3>
            <div className="skills__used">Used in:</div>
            <ul>
              {this.state.projects
                ? this.state.projects.map((item, index) => {
                    return (
                      <li key={`${item + index}`} className="skills__projects">
                        {item}
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}
