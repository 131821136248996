import React from "react";

import ProfileImage from "../../assets/profile/profile-placeholder.jpg";
import GitHub from "../../assets/icons/github.svg";
import LinkedIn from "../../assets/icons/linkedin.svg";
import Instagram from "../../assets/icons/instagram.svg";

import "./About.scss";

export default class About extends React.Component {
  componentDidMount() {
    const about = document.getElementsByClassName("about")[0];
    const aboutHeading = document.getElementsByClassName("about__heading")[0];
    const aboutProfileImage = document.getElementsByClassName(
      "about__profile-image-animation-container"
    )[0];
    const aboutBioContainer = document.getElementsByClassName(
      "about__bio-container"
    )[0];
    const aboutUnderline = document.getElementsByClassName(
      "about__heading-underline"
    )[0];
    const aboutIcons = document.getElementsByClassName(
      "about__social-container"
    )[0];

    if (aboutHeading) {
      this.observer.observe(aboutHeading);
    }
    this.aboutSectionObserver.observe(about);
    this.aboutProfileImageObserver.observe(aboutProfileImage);
    this.aboutBioObserver.observe(aboutBioContainer);
    this.aboutUnderlineObserver.observe(aboutUnderline);
    this.aboutSocialObserver.observe(aboutIcons);
  }

  componentDidUpdate(prevProps, prevState) {
    const imageContainer = document.getElementsByClassName(
      "about__profile-image-animation-container"
    )[0];
    const image = document.getElementsByClassName("about__profile-image")[0];
    const imageHeight = imageContainer.offsetHeight;
    if (this.props.viewWidth < 768) {
      image.removeAttribute("style");
    } else if (this.props.viewWidth >= 768 && this.props.viewWidth < 1024) {
      image.setAttribute(
        "style",
        `width: unset; height: ${imageHeight + 10}px`
      );
    } else if (this.props.viewWidth >= 1024 && this.props.viewWidth < 1530) {
      image.setAttribute(
        "style",
        `width: unset; height: ${imageHeight + 40}px`
      );
    } else if (this.props.viewWidth >= 1560 && this.props.viewWidth < 1630) {
      const imageWidth = imageContainer.offsetWidth;
      image.setAttribute(
        "style",
        `width: ${imageWidth}px; transform: translate(0%)`
      );
    } else if (this.props.viewWidth >= 1630 && this.props.viewWidth < 1700) {
      const imageWidth = imageContainer.offsetWidth;
      image.setAttribute(
        "style",
        `width: ${imageWidth}px; transform: translateY(-10%)`
      );
    } else if (this.props.viewWidth >= 1700) {
      const imageWidth = imageContainer.offsetWidth;
      image.setAttribute(
        "style",
        `width: ${imageWidth}px; transform: translateY(-15%)`
      );
    }
  }
  options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  // Callback function executed during observe.
  callback = (entries, options) => {
    // Target the first entry available.
    let observedEl = entries[0];

    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("about__greeting--animate");
    } else {
      observedEl.target.className = "about__heading";
    }
  };

  aboutSectionOptions = {
    root: null,
    rootMargin: "0px",
    threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  };

  aboutSectionCallback = (entries, options) => {
    const observedEl = entries[0];
    const homePage = document.getElementsByClassName("home")[0];
    const aboutHeading = document.getElementsByClassName("about__heading")[0];
    const heroFullStack = document.getElementsByClassName("hero__full")[0];
    const bio = document.getElementsByClassName("about__bio-container")[0];
    const skipButton = document.getElementsByClassName("skip-button")[0];
    if (
      observedEl.target.getBoundingClientRect().top < 200 &&
      observedEl.isIntersecting
    ) {
      aboutHeading.setAttribute("style", "color: black");
      homePage.classList.add("home--background-white");
      heroFullStack.setAttribute("style", "color: black");
      bio.classList.add("about__bio-container--black");
      skipButton.className = "skip-button skip-button--hide";
    } else if (observedEl.target.getBoundingClientRect().top > 300) {
      homePage.className = "home home--background-black";
      aboutHeading.setAttribute("style", "color: white");
      heroFullStack.setAttribute("style", "color: white");
      bio.classList.remove("about__bio-container--black");
    } else if (observedEl.target.getBoundingClientRect().top < -780) {
      aboutHeading.setAttribute("style", "color: white");
      heroFullStack.setAttribute("style", "color: white");
      bio.classList.remove("about__bio-container--black");
    } else if (!observedEl.isIntersecting) {
      homePage.classList.remove("home--background-white");
    }
  };

  aboutProfileImageOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  aboutProfileImageCallback = (entries, options) => {
    const observedEl = entries[0];
    const image = document.getElementsByClassName(
      "about__profile-image-container"
    )[0];

    if (observedEl.isIntersecting) {
      image.classList.add("about__profile-image-container--animate");
    } else if (
      !observedEl.isIntersecting &&
      observedEl.target.getBoundingClientRect().top > 0
    ) {
      image.classList.remove("about__profile-image-container--animate");
    }
  };

  aboutBioOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  aboutBioCallback = (entries, options) => {
    const observedEl = entries[0];
    const greeting = document.getElementsByClassName("about__greeting")[0];
    const bio = document.getElementsByClassName("about__bio")[0];
    if (
      observedEl.isIntersecting &&
      observedEl.target.getBoundingClientRect().top > 0
    ) {
      greeting.classList.add("about__greeting--animate");
      bio.classList.add("about__greeting--animate-two");
    } else if (
      observedEl.isIntersecting &&
      observedEl.target.getBoundingClientRect().top < 0
    ) {
      greeting.classList.add("about__greeting--animate-two");
      bio.classList.add("about__greeting--animate");
    } else {
      greeting.classList.remove("about__greeting--animate");
      bio.classList.remove("about__greeting--animate-two");
      greeting.classList.remove("about__greeting--animate-two");
      bio.classList.remove("about__greeting--animate");
    }
  };

  aboutUnderlineCallback = (entries, options) => {
    const observedEl = entries[0];
    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("about__heading-underline--animate");
    } else {
      observedEl.target.classList.remove("about__heading-underline--animate");
    }
  };

  aboutSocialCallback = (entries, options) => {
    const observedEl = entries[0];
    const icons = document.getElementsByClassName("about__icons");
    if (observedEl.isIntersecting) {
      for (let i = 0; i < icons.length; i++) {
        if (i === 2) {
          icons[i].classList.add("about__icons--one");
        } else if (i === 1) {
          icons[i].classList.add("about__icons--two");
        } else {
          icons[i].classList.add("about__icons--three");
        }
      }
    } else {
      for (let i = 0; i < icons.length; i++) {
        if (i === 2) {
          icons[i].classList.remove("about__icons--one");
        } else if (i === 1) {
          icons[i].classList.remove("about__icons--two");
        } else {
          icons[i].classList.remove("about__icons--three");
        }
      }
    }
  };

  // Construct Intersection Observer.
  observer = new IntersectionObserver(this.callback, this.options);
  aboutSectionObserver = new IntersectionObserver(
    this.aboutSectionCallback,
    this.aboutSectionOptions
  );
  aboutUnderlineObserver = new IntersectionObserver(
    this.aboutUnderlineCallback,
    this.aboutBioOptions
  );
  aboutProfileImageObserver = new IntersectionObserver(
    this.aboutProfileImageCallback,
    this.aboutProfileImageOptions
  );
  aboutBioObserver = new IntersectionObserver(
    this.aboutBioCallback,
    this.aboutBioOptions
  );
  aboutSocialObserver = new IntersectionObserver(
    this.aboutSocialCallback,
    this.aboutBioOptions
  );

  render() {
    return (
      <section className="about">
        <h2 className="about__heading about__heading--white">About</h2>
        <div className="about__heading-underline"></div>
        <div className="about__container">
          <div className="about__profile-image-animation-container">
            <div className="about__profile-image-container">
              <img
                className="about__profile-image"
                alt="Roy Lee profile"
                src={ProfileImage}
              />
            </div>
          </div>
          <div className="about__bio-container">
            <h2 className="about__greeting">Hello,</h2>
            <p className="about__bio">
              I’m Roy, a Full Stack Developer dedicated to transforming
              conceptual ideas into beautiful, functional web applications. As
              more people and businesses undergo their age of digital
              transformation—now more so than ever—I aspire to make a difference
              by creating technology that challenges tradition and shapes
              growing industries. I take pride in being able to transform code
              into serviceable products that positively impact my community.
              <br />
              <br />
              I am a 2019 graduate of BrainStation’s full-stack web development
              diploma program, where I strengthened my skills in HTML, CSS, and
              Javascript in a challenging bootcamp timeframe. I am most familiar
              with ReactJS as a front-end framework and NodeJS and ExpressJS as
              back-end frameworks, but have also completed various personal
              projects using skills in other technologies such as React Native
              and Firebase. After graduation, I served a fulfilling role as a
              Teaching Assistant for the same diploma program over eight months.
              Equipped with experience in team management and teaching prior to
              my career transformation. Most recently I worked at Shopify as a 
              Front End Engineer where I maintained, updated, and enhanced performance
              and developer experience of Shopify’s Admin page which is the company’s
              largest web repository responsible for store management. I am currently 
              looking for an opportunity to work as part of a passionate software development
              team in Toronto, Canada!
              <br />
              <br />
              In my free time, you can find me playing sports, teaming up with
              my friends on video games, and working on my latest personal
              coding project. Please don’t hesitate to reach out to me at {""}
              <span className="about__email">royk.leejr@gmail.com!</span>
            </p>
            <div className="about__social-container">
              <a
                className="about__anchor"
                href="https://github.com/royleejr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="about__icons" src={GitHub} alt="Github logo" />
              </a>
              <a
                className="about__anchor"
                href="https://www.linkedin.com/in/roy-lee-jr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="about__icons"
                  src={LinkedIn}
                  alt="LinkedIn logo"
                />
              </a>
              <a
                className="about__anchor"
                href="https://www.instagram.com/roykleejr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="about__icons"
                  src={Instagram}
                  alt="Instagram logo"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
