import React from "react";

import Project from "../Project/Project";
import { projectData } from "../../data/projectData";

import "./AllProjects.scss";

export default class AllProjects extends React.Component {
  componentDidMount() {
    const projectsHeading = document.getElementsByClassName(
      "projects__heading"
    )[0];

    this.projectsHeadingObserver.observe(projectsHeading);
  }

  projectsHeadingOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  projectsHeadingCallback = (entries, options) => {
    const observedEl = entries[0];
    const projectsUnderline = document.getElementsByClassName(
      "projects__underline"
    )[0];
    if (observedEl.isIntersecting) {
      observedEl.target.classList.add("projects__heading--animate");
      projectsUnderline.classList.add("projects__underline--animate");
    } else {
      observedEl.target.classList.remove("projects__heading--animate");
      projectsUnderline.className = "projects__underline";
    }
  };

  projectsHeadingObserver = new IntersectionObserver(
    this.projectsHeadingCallback,
    this.projectsHeadingOptions
  );

  render() {
    return (
      <section className="projects">
        <h2 className="projects__heading">Projects</h2>
        <div className="projects__underline"></div>
        <div>
          {projectData.map((item, index) => {
            return (
              <Project
                key={item + index}
                data={item}
                viewWidth={this.props.viewWidth}
                viewHeight={this.props.viewHeight}
                projectLocation={this.props.projectLocation}
              />
            );
          })}
        </div>
      </section>
    );
  }
}
