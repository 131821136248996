import React from "react";

import SvgIcon from "../SvgIcon/SvgIcon";

import "./Header.scss";

let buttonOpen = false;
export default class Header extends React.Component {
  state = {
    toggle: false,
    dropDown: false,
  };

  headerToggle = async (event, link) => {
    const button = document.getElementsByClassName("skip-button")[0];
    if (!link) {
      this.props.toggleHeaderState();
      await this.setState({
        toggle: !this.state.toggle,
      });
      this.expand();
    } else {
      if (button.className.includes("animate")) {
        button.className = "skip-button skip-button--hidden";
        buttonOpen = true;
      } else {
        if (buttonOpen) {
          button.className = "skip-button skip-button--animate";
          buttonOpen = false;
        }
      }
      this.props.toggleHeaderState();
      event.preventDefault();
      await this.setState({
        toggle: !this.state.toggle,
      });
      this.expand();
    }
  };

  expand = () => {
    const body = document.querySelector("body");
    body.classList.add("body-header");
    const header = document.querySelector("header");
    if (this.state.toggle) {
      document.getElementById("input").checked = true;
      header.classList.add("header--expand");
      var nav = document.getElementsByClassName("header__nav");
      nav[0].classList.remove("header__nav--hide");
      const links = document.getElementsByClassName("header__link");
      for (let i = 0; i < links.length; i++) {
        links[i].classList.add(`header__link--${i}`);
      }
    } else {
      body.classList.remove("body-header");
      document.getElementById("input").checked = false;
      header.classList.remove("header--expand");
      document
        .getElementsByClassName("header__nav")[0]
        .classList.add("header__nav--hide");
      const links = document.getElementsByClassName("header__link");
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove(`header__link--${i}`);
      }
    }
  };

  logoClick = () => {
    const home = document.getElementsByClassName("home")[0];
    home.className = "home home--background-black";
    if (buttonOpen) {
      buttonOpen = false;
    }
    if (this.state.toggle) {
      this.headerToggle();
    }
  };

  render() {
    return (
      <header className="header">
        <div className="header__container">
          <div className="header__top">
            <svg
              onClick={() => {
                this.props.scrollToTop("logo");
                this.logoClick();
              }}
              tabIndex="0"
              className="header__logo"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="320"
              height="320"
              viewBox="0 0 320 320"
              htmlFor="input"
            >
              <g
                transform="translate(0,320) scale(0.1,-0.100000)"
                stroke="none"
              >
                <path d="M0 1600 l0 -1600 1600 0 1600 0 0 1600 0 1600 -1600 0 -1600 0 0 -1600z m3070 0 l0 -1470 -1470 0 -1470 0 0 1470 0 1470 1470 0 1470 0 0 -1470z" />
                <path d="M825 2854 c-108 -16 -239 -56 -317 -95 -54 -27 -58 -32 -58 -65 0 -38 18 -80 57 -131 17 -24 32 -33 53 -33 16 0 32 5 35 10 6 10 145 84 145 77 0 -27 -152 -399 -291 -715 -33 -73 -59 -143 -59 -156 0 -29 78 -119 119 -135 16 -7 42 -11 57 -9 24 2 31 10 41 43 64 221 87 285 166 478 8 19 18 27 37 27 24 0 27 -8 68 -151 78 -275 130 -379 205 -408 50 -19 104 -3 158 46 47 43 49 69 9 108 -47 45 -116 154 -180 287 -33 68 -60 126 -60 129 0 4 19 9 41 13 80 13 186 71 253 138 85 86 110 141 110 243 1 44 -5 97 -13 119 -17 48 -71 109 -125 141 -91 53 -263 68 -451 39z m412 -178 c134 -69 58 -238 -141 -311 -70 -26 -211 -53 -221 -43 -7 6 110 245 151 309 13 21 24 46 24 55 0 14 10 16 78 12 44 -3 91 -12 109 -22z" />
                <path d="M1662 1652 c-634 -629 -782 -782 -782 -803 0 -32 57 -89 89 -89 17 0 225 200 807 777 645 638 784 781 784 803 0 34 -57 90 -90 89 -19 0 -207 -181 -808 -777z" />
                <path d="M2185 1518 c-2 -7 -20 -80 -40 -163 -60 -243 -166 -547 -289 -824 -25 -57 -46 -116 -46 -130 0 -35 48 -100 100 -136 l40 -27 112 17 c62 10 183 21 268 24 l155 6 3 62 c2 47 -2 75 -18 109 l-20 47 -79 -6 c-44 -3 -130 -18 -191 -32 -62 -14 -114 -24 -117 -21 -6 6 259 683 322 823 48 104 46 128 -12 185 -27 26 -65 54 -85 63 -45 18 -97 20 -103 3z" />
              </g>
            </svg>
            <button
              className="header__menu"
              onClick={(event) => this.headerToggle(event, "link")}
            >
              <input type="checkbox" id="input" />
              <label
                className="toggle"
                id="header__span--light"
                htmlFor="input"
              >
                <div>
                  <div>
                    <span className="header__span"></span>
                    <span className="header__span"></span>
                  </div>
                  <svg className=".header__svg">
                    <use xlinkHref="#path" />
                  </svg>
                  <svg className=".header__span">
                    <use xlinkHref="#path" />
                  </svg>
                </div>
              </label>
              <svg
                className="header__span"
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "none" }}
              >
                <symbol
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 44 44"
                  id="path"
                >
                  <path d="M22,22 L2,22 C2,11 11,2 22,2 C33,2 42,11 42,22"></path>
                </symbol>
              </svg>
            </button>
          </div>
        </div>
        <nav className="header__nav header__nav--hide">
          <button
            className="header__link"
            onClick={() => {
              this.headerToggle();
              this.props.headerScroll("home");
            }}
          >
            Home
          </button>
          <button
            className="header__link"
            onClick={() => {
              this.headerToggle();
              this.props.headerScroll("about");
            }}
          >
            About
          </button>
          <button
            className="header__link"
            onClick={() => {
              this.headerToggle();
              this.props.headerScroll("projects");
            }}
          >
            Projects
          </button>
          <button
            className="header__link"
            onClick={() => {
              this.headerToggle();
              this.props.headerScroll("skills");
            }}
          >
            Skills
          </button>
          <button
            className="header__link"
            onClick={() => {
              this.headerToggle();
              this.props.headerScroll("contact");
            }}
          >
            Contact
          </button>
          <div className="header__bottom-container">
            <div className="header__social-container">
              <a
                className="header__anchor"
                href="https://github.com/royleejr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIcon class="header__icons" icon="github" />
              </a>
              <a
                className="header__anchor"
                href="https://www.linkedin.com/in/roy-lee-jr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIcon class="header__icons" icon="linkedin" />
              </a>
              <a
                className="header__anchor"
                href="https://www.instagram.com/roykleejr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIcon class="header__icons" icon="instagram" />
              </a>
            </div>
            <a
              className="header__email"
              href="mailto:royk.leejr@gmail.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              royk.leejr@gmail.com
            </a>
          </div>
        </nav>
      </header>
    );
  }
}
